import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { Brand, ContentType } from 'src/app/models';
import { LoggerService } from '../general/logger.service';
import { ContentfulService } from './contentful.service';
import { EntryService } from './entry.service';
import { CacheService } from '../general';
import { TransferState } from '@angular/platform-browser';
import { LocaleService } from './locale.service';

@Injectable({
  providedIn: 'root'
})
export class BrandService extends EntryService<Brand> {

  constructor(
    contentful: ContentfulService,
    localeService: LocaleService,
    cache: CacheService,
    logger: LoggerService,
    transferState: TransferState,
    @Inject(PLATFORM_ID) public platformId: any,
  ) {
    super(ContentType.BRAND, contentful, localeService, logger, cache, platformId, transferState);
  }
}
