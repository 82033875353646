<div class="home">
  <div class="home-section pillar-pages-hero">
    <div class="container">
      <div class="inner">
        <h1>
          <span class="sub-heading">{{content?.heroPreHeading}}</span>
          {{content?.heroHeading}}
        </h1>
        <div [innerHTML]="content?.heroText|html"></div>
      </div>
      <app-pillar-pages-list>
        <ng-template #template let-page>
          <a [routerLink]="['/', page?.slug]" class="text-link">
            <asset [asset]="page.iconInPurple"></asset>
            <span>{{page.shortTitle || page.title}}</span>
          </a>
        </ng-template>
      </app-pillar-pages-list>
    </div>
  </div>

  <div class="home-section banner">
    <div class="container">
      <app-banner [count]="1" layouts="horizontal"></app-banner>
    </div>
  </div>

  <div class="home-section section-1 shadowed">
    <div class="container">
      <div class="inner">
        <h1>
          <span class="sub-heading">{{content?.section1PreHeading}}</span>
          {{content?.section1Heading}}
        </h1>
        <div class="text-wrapper">
          <asset class="image-block" [id]="content?.section1Image?.id || content?.section1Image?.sys?.id"></asset>
          <div class="text-block" [innerHTML]="content?.section1Text|html"></div>
        </div>
      </div>

    </div>
  </div>

  <div class="home-section pillar-pages-content">
    <div class="container">
      <app-pillar-pages-list>
        <ng-template #template let-page>
          <div class="inner">
            <div class="icon">
              <asset [id]="page.iconInColor?.id || page.iconInColor?.sys?.id"></asset>
            </div>
            <div class="flex-grow-1">
              <h2>{{page.title}}</h2>
              <div class="summary">
                <div class="mr-lg-2" [innerHTML]="page.summary|html"></div>
                <button [routerLink]="['/', page.slug]" class="btn">{{'Learn More'|contentful|async}}</button>
              </div>
            </div>
          </div>
        </ng-template>
      </app-pillar-pages-list>
    </div>
  </div>

  <div class="home-section articles-section">
    <div class="container">
      <div class="inner">
        <div class="text-wrapper">
          <h2>
            <span class="sub-heading">{{content?.section2PreHeading}}</span>
            {{content?.section2Heading}}
          </h2>
          <asset class="d-lg-none image-block" [id]="content?.section2Image?.id || content?.section2Image?.sys?.id"></asset>
          <div class="text-block" [innerHTML]="content?.section2Text|html"></div>
          <app-banner [count]="1" layouts="square"></app-banner>
        </div>
        <asset class="d-none d-lg-block image-block" [id]="content?.section2Image?.id || content?.section2Image?.sys?.id"></asset>
      </div>
    </div>
    <div class="container callout purple">
      <span class="image-left"></span>
      <div [innerHTML]="content?.section2Callout|html"></div>
      <span class="image-right"></span>
    </div>
    <div class="container">
      <app-articles-list>
        <ng-template #template let-article let-parent="parent">
          <div class="article-inner" [routerLink]="['/' + (parent?.slug || ''), article.slug]">
            <asset class="image-block" [id]="article?.coverImage?.id || article?.coverImage?.sys?.id">
              <ng-template #assetTemplate let-asset>
                <img class="hidden-img" [attr.src]="asset?.file.url" loading="lazy" />
                <span class="image-content" [style.backgroundImage]="'url(' + asset?.file.url + ')'"></span>
              </ng-template>
            </asset>
            <div class="inner-content">
              <p class="bold">{{article.title}}</p>
              <div class="body-preview" [innerHTML]="article.bodyPreview|html"></div>
              <p class="text-smaller">
                <span>{{article.date|date}}</span>
                <ng-container *ngIf="article.tags?.length">
                  |
                  <span class="bold" *ngFor="let tag of article.tags; index as i">{{tag + (i === (article.tags.length - 1) ? '' : ' / ')}}</span>
                </ng-container>
              </p>
            </div>
          </div>
        </ng-template>
      </app-articles-list>
    </div>
  </div>

  <div class="home-section">
    <div class="container">
      <app-banner [count]="1" layouts="horizontal"></app-banner>
    </div>
  </div>

</div>
