import { Component, OnInit, OnDestroy, Inject, PLATFORM_ID, HostListener, ElementRef, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoggerService } from 'src/app/services';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'sticky-container',
  templateUrl: './sticky-container.component.html'
})
export class StickyContainerComponent implements OnInit, OnDestroy {
  private subs: Subscription[] = [];
  private updateTimer: any;
  private isPlatformBrowser = isPlatformBrowser(this.platformId);

  screenWidth: number;
  minScreenWidth: number = 992;
  marginTop: number;
  marginBottom: number;
  parentHeight: number;
  containerHeight: number;
  containerWidth: number;
  stickyStart: number;
  stickyEnd: number;

  container: HTMLElement;
  parent: HTMLElement;

  stickTo: "top" | "bottom" = "top";
    state: string;
  @Input("stickTo") set _stickTo(stickTo: "top" | "bottom") {
    this.stickTo = stickTo;
  }

  offset: number = 0;
  @Input("offset") set _offset(offset: number) {
    this.offset = offset;
  }

  constructor(
    private logger: LoggerService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: any,
    private elementRef: ElementRef<HTMLElement>
  ) { }

  @HostListener('window:resize')
  onResize(timeout: number = 500) {
    if (this.isPlatformBrowser) {
      if (this.updateTimer) {
        clearTimeout(this.updateTimer);
      }
      this.screenWidth = window.innerWidth;
      if (this.screenWidth > this.minScreenWidth) {
        this.updateTimer = setTimeout(() => {
          this.update();
        }, timeout);
      }
    }
  }

  @HostListener('window:scroll')
  onWindowScroll() {
    if (this.isPlatformBrowser) {
      const scrollTop = this.document.body.scrollTop || this.document.documentElement.scrollTop;
      if (scrollTop > this.stickyStart && scrollTop < this.stickyEnd) {
        this.state = "is-sticky";
      }
      else if (scrollTop > this.stickyEnd) {
        this.state = "is-sticky-end";
      }
      else {
        this.state = "";
      }
      this.setState();
    }
  }

  setState() {
    if (this.state === "is-sticky") {
      this.container.classList.add('sticky');
      this.container.classList.remove('absolute');
      this.container.style.width = this.containerWidth + "px";
      this.container.style[this.stickTo] = this.offset + "px";
    }
    else if (this.state === "is-sticky-end") {
      this.container.classList.remove('sticky');
      this.container.classList.add('absolute');
      this.container.style[this.stickTo] = "";
      this.container.style.width = this.containerWidth + "px";
    }
    else {
      this.resetState();
    }
  }

  resetState() {
    this.container.classList.remove('sticky');
    this.container.classList.remove('absolute');
    this.container.removeAttribute("style");
  }

  update() {
    this.parent.removeAttribute("style");
    this.resetState();
    this.marginTop = parseInt(getComputedStyle(this.container).marginTop);
    this.marginBottom = parseInt(getComputedStyle(this.container).marginBottom);
    this.containerWidth = this.parent.clientWidth;
    this.containerHeight = this.container.clientHeight;
    this.parent.style.minHeight = this.containerHeight + "px";
    this.calculateStart();
    this.calculateEnd();
    this.setState();
  }

  calculateStart() {
    const windowTop = window.scrollY;
    const parentRect = this.parent.getBoundingClientRect();
    const containerRect = this.container.getBoundingClientRect();
    const parentTop = this.stickTo === "top" ? parentRect.y : containerRect.bottom - this.document.documentElement.clientHeight;
    const offsetTop = parentTop + windowTop;
    this.stickyStart = offsetTop - (this.stickTo === "top" ? this.marginTop + this.offset : this.marginBottom - this.offset);
  }

  calculateEnd() {
    const parentHeight = this.parent.clientHeight;
    this.stickyEnd = ((parentHeight + this.stickyStart) - (this.containerHeight));
  }

  setup() {
    if (this.elementRef && this.elementRef.nativeElement) {
      this.container = this.elementRef.nativeElement;
      this.parent = this.elementRef.nativeElement.parentElement;
    }
    this.container.classList.add("sticky-container");
    this.parent.classList.add("sticky-wrapper");
    this.container.classList.add("stick-to-" + this.stickTo);
  }

  ngOnInit() {
    if (this.isPlatformBrowser) {
      this.setup();
      this.onResize(100);
      setTimeout(() => this.onResize(1500), 1000);
    }
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
}
