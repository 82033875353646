import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TransferState } from '@angular/platform-browser';
import { ContentType, DictionaryLookup } from 'src/app/models';
import { CacheService } from '../general';
import { LoggerService } from '../general/logger.service';
import { ContentfulService } from './contentful.service';
import { EntryService } from './entry.service';
import { LocaleService } from './locale.service';

@Injectable({
  providedIn: 'root'
})
export class DictionaryService extends EntryService<DictionaryLookup> {

  listBy = "property";
  order = "";

  constructor(
    private client: ContentfulService,
    localeService: LocaleService,
    cache: CacheService,
    logger: LoggerService,
    transferState: TransferState,
    @Inject(PLATFORM_ID) public platformId: any,
  ) {
    super(ContentType.DICTIONARY_LOOKUP, client, localeService, logger, cache, platformId, transferState);
    this.init();
  }

  private init() {
    if (isPlatformBrowser(this.platformId)) {
      this.getAll().subscribe();
    }
  }

}
