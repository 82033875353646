import { HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, interval, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, map, share, skipWhile, switchMap, tap } from 'rxjs/operators';
import { LoggerService } from './logger.service';
import { CacheService } from './cache.service';
import { isPlatformBrowser } from '@angular/common';

export type ReturnObjectOptions = 'all' | 'body' | 'data';

export interface HttpOptions {
  headers?: HttpHeaders;
  reportProgress?: boolean;
  params?: HttpParams;
  responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
  withCredentials?: boolean;
}

export interface Poll {
  subject?: BehaviorSubject<any>;
  subscription?: Subscription;
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  /**
    * @property ApiService.httpOptions
    * @description A set of standardised HttpRequest options
    */
  private httpOptions: HttpOptions = {
    responseType: 'json'
  }
  public currentPoll: Poll = {};
  private verbose: boolean = false;
  private isPlatformBrowser: boolean = isPlatformBrowser(this.platformId);

  constructor(
    private http: HttpClient,
    private logger: LoggerService,
    @Inject(PLATFORM_ID) private platformId: any,
    private cache: CacheService
  ) { }

  public cancelPoll() {
    if (this.currentPoll) {
      this.currentPoll.subject && this.currentPoll.subject.complete();
      this.currentPoll.subscription && this.currentPoll.subscription.unsubscribe();
    }
    this.currentPoll = {};
  }

  public poll<T>(
    url: string, testFn: (resp: T) => boolean,
    params?: { [param: string]: string }, returnObj?: ReturnObjectOptions, int: number = 1000, infinite?: boolean
  ): Observable<T> {
    this.cancelPoll();
    this.currentPoll.subject = this.currentPoll.subject || new BehaviorSubject<T>(null);

    this.currentPoll.subscription = this.runPoll<T>(url, testFn, params, returnObj, infinite)
      .pipe(switchMap(() => {
        return interval(int)
          .pipe(switchMap(() => {
            return this.runPoll<T>(url, testFn, params, returnObj, infinite);
          }));
      }))
      .subscribe();
    return this.currentPoll.subject.asObservable();
  }

  private runPoll<T>(
    url: string, testFn: (resp: T) => boolean,
    params?: { [param: string]: string }, returnObj?: ReturnObjectOptions, infinite?: boolean
  ): Observable<T> {
    return this.get<T>(url, params, returnObj)
      .pipe(
        tap((resp) => {
          if (resp && (!testFn || testFn(resp))) {
            this.currentPoll.subject.next(resp);
            if (!infinite) {
              this.currentPoll.subscription.unsubscribe();
            }
          }
        }),
        catchError((err) => {
          this.logger.log(err);
          this.currentPoll.subscription.unsubscribe();
          return of(null);
        }),
        share()
      );
  }

  public get<T = any>(url: string, params?: { [param: string]: string | number }, returnObj?: ReturnObjectOptions, options?: HttpOptions, handleErrors: boolean = true, allowCache?: boolean): Observable<T> {
    let opts: HttpOptions = { ...this.httpOptions, ...(options || {}) };
    if (params) {
      opts.params = new HttpParams({ fromObject: params });
    }
    let req = new HttpRequest<T>('GET', url, opts);
    return this.sendRequest<T>(req, returnObj, handleErrors, allowCache);
  }

  public post<T>(url: string, data: T = null, returnObj?: ReturnObjectOptions, options?: HttpOptions, handleErrors: boolean = true): Observable<T> {
    let req = new HttpRequest<T>('POST', url, data, { ...this.httpOptions, ...(options || {}) });
    return this.sendRequest<T>(req, returnObj, handleErrors);
  }

  public patch<T>(url: string, data: T, returnObj?: ReturnObjectOptions, options?: HttpOptions, handleErrors: boolean = true): Observable<T> {
    const req = new HttpRequest<T>('PATCH', url, data, { ...this.httpOptions, ...(options || {}) });
    return this.sendRequest<T>(req, returnObj, handleErrors);
  }

  public put<T>(url: string, data: T, returnObj?: ReturnObjectOptions, options?: HttpOptions, handleErrors: boolean = true): Observable<T> {
    const req = new HttpRequest<T>('PUT', url, data, { ...this.httpOptions, ...(options || {}) });
    return this.sendRequest<T>(req, returnObj, handleErrors);
  }

  public delete<T>(url: string, params?: { [param: string]: string }, returnObj: ReturnObjectOptions = "all", options?: HttpOptions, handleErrors: boolean = true): Observable<T> {
    let opts: HttpOptions = { ...this.httpOptions, ...(options || {}) };
    if (params) {
      opts.params = new HttpParams({ fromObject: params });
    }
    let req = new HttpRequest<T>('DELETE', url, opts);
    return this.sendRequest<T>(req, returnObj, handleErrors);
  }

  /**
   * @method ApiService.sendRequest
   * @description A standardised call to the server which adds any generic information to the request
   * @param req The request to be issued
   * @param T The type of object to be returned
   * @param baseUrl (optional) The base API url. These are mainly stored in the environment object and the default is environment.panelServicesUrl
   */
  private sendRequest<T>(req: HttpRequest<T>, returnObj?: ReturnObjectOptions, handleErrors?: boolean, allowCache?: boolean): Observable<T> {

    // Use this code to add the environment specific server URL to the front of the request URL
    // Not needed here as this is done with interceptors
    //const r = req.clone<T>({
    //  url: (baseUrl || environment.api_url) + req.url,
    //});

    //const allowSentry = true;
    //let transaction;
    //let span;
    //if (allowSentry) {
    //  transaction = Sentry.startTransaction({
    //    name: "API Request",
    //    description: `${req.method} ${req.url}`,
    //    tags: { type: req.method },
    //  });

    //  span = transaction.startChild({
    //    tags: { phase: "request" },
    //    data: {
    //      request: {
    //        params: req.params,
    //        url: req.url,
    //        body: req.body,
    //        method: req.method,
    //      }
    //    },
    //    op: req.method.toUpperCase(),
    //    description: `Send Request`,
    //  });
    //}

    if (this.verbose) {
      this.logger.log('send HttpResponse');
    }
    const obs = this.isPlatformBrowser && allowCache ? this.cache.processUrlRequest(req.urlWithParams) : throwError(null);

    return obs.pipe(
      catchError(() => this.http.request<T>(req).pipe(
        //Skip HttpClient Event notifications
        skipWhile((resp: HttpResponse<T>) => {
          return resp.type < HttpEventType.Response;
        }),
        switchMap((resp: any) => {
          if (this.verbose) {
            this.logger.groupCollapsed("ApiService.success");
            this.logger.log('map HttpResponse');
            this.logger.groupEnd();
          }
          //if (allowSentry) {
          //  span.finish();
          //  const span2 = transaction.startChild({
          //    tags: { phase: "response" },
          //    data: {
          //      response: resp
          //    },
          //    op: req.method.toUpperCase(),
          //    description: `Handle Response`,
          //  });
          //  span2.finish();
          //  transaction.finish();
          //}
          let returnValue;
          if (returnObj === 'all') returnValue = resp as any;
          else if (!returnObj || returnObj === 'body') returnValue = resp.body;
          else returnValue = (resp.body && typeof resp.body.data !== "undefined" ? resp.body.data : resp.body || resp);
          return this.isPlatformBrowser && allowCache ? this.cache.processUrlResponse(req.urlWithParams, returnValue) : of(returnValue);
        }),
        catchError((resp: HttpErrorResponse) => {
          //if (allowSentry) {
          //  span.finish();
          //  const span2 = transaction.startChild({
          //    tags: { phase: "response" },
          //    data: {
          //      response: resp
          //    },
          //    op: req.method.toUpperCase(),
          //    description: `Catch Error Response`,
          //  });
          //  span2.finish();
          //}
          return this.handleError(resp, handleErrors);
        }),
      ))
    );
  }

  /**
   * @method ApiService.handleError
   * @description A standardised error handler for failed API calls
   * @param error The error to be handled
   */
  private handleError(error: HttpErrorResponse, handleErrors: boolean): Observable<any> {
    if (handleErrors) {
      this.logger.groupCollapsed("ApiService.handleError");
      const resp: any = (error && error.error || error) as HttpErrorResponse;
      const status = (error && error.status) || (resp && resp.status) || null;
      if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred.
        this.logger.warn('An error occurred:', error.error.message);
        //if (!status || (status !== 401 && resp.status !== 422)) {
        //  Sentry.captureException(resp, { extra: { handled: true, request_url: error.url }, tags: { "api_response": true, "api_origin": error.url ? new URL(error.url).origin : "unknown" } });
        //}
        this.logger.groupEnd();
        return throwError(error.error);
      }
      else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        // so we want to extract what we can before returning a standardised response
        this.logger.warn(
          `Server returned error code ${error.status}, ` +
          `body was: ${error.error}`);
        try {
          let msg = "Failed to complete action";
          let e: any = new Error("An unknown error occurred");
          if (resp.error) {
            e = new Error(resp.error.message || resp.error);
          }
          if (resp.errors && resp.errors[0]) {
            e = new Error(resp.errors[0].message || resp.errors[0]);
          }
          //if (!status || (status !== 401 && status !== 422)) {
          //  Sentry.captureException(e, { extra: { handled: true, request_url: error.url, quiet: true }, tags: { "api_response": true, "api_origin": error.url ? new URL(error.url).origin : "unknown" } });
          //}
          if (status) {
            switch (status) {
              case 401:
                msg = "Please login to proceed";
                break;
              case 500:
              case 501:
              case 502:
                msg = "A server error occurred";
                break;
              default:
                msg = "Failed to load content";
            }
          }
          this.logger.warn("Error: " + e.message);
          this.logger.warn(msg);
          this.logger.groupEnd();
          return throwError(new Error(msg));
        }
        catch (e) {
          this.logger.warn(e)
          //Sentry.captureException(error && error.error || error, { extra: { handled: true } });
          this.logger.groupEnd();
          return throwError(error && error.error || error);
        }
      }
    }
    else {
      return throwError(error);
    }
  }
}
