import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Subscription } from 'rxjs';
import { Article, SkeletonRow } from 'src/app/models';
import { LoggerService, SkeletonService, ArticleService } from 'src/app/services';
import { ContentfulApiService } from '../../../services/contentful/contentful-api.service';

@Component({
  selector: 'app-featured-article',
  templateUrl: './featured-article.component.html'
})
export class FeaturedArticleComponent implements OnInit, OnDestroy {

  private subs: Subscription[] = [];

  article: Article;
  @Input('id') set _id(id: string) {
    this.getArticle(id);
  }

  baseUrl: string = "";
  @Input('baseUrl') set _baseUrl(baseUrl: string) {
    if (baseUrl) {
      this.baseUrl = baseUrl;
    }
  }

  isBrowser: boolean = isPlatformBrowser(this.platformId);

  skeletonLayout: SkeletonRow[] = [
    this.skeletonService.getTitle()
  ];

  constructor(
    private skeletonService: SkeletonService,
    private articleService: ArticleService,
    private contentful: ContentfulApiService,
    private logger: LoggerService,
    @Inject(PLATFORM_ID) private platformId: any,
  ) { }

  getArticle(id: string) {
    if (id) {
      this.subs.push(
        this.articleService.get(id, "id").subscribe(article => {
          this.article = article;
        })
      );
    }
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

}
