import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LoggerService } from '../services';

@Injectable()
export class ContentfulInterceptor implements HttpInterceptor {

  private tokenName: string = 'Authorization';

  constructor(
    private logger: LoggerService
  ) {
  }

  /**
   * @method AuthInterceptor.intercept
   * @description Intercepts all API calls and adds the authorisation token to it
   * @param req The request being issued
   * @param next The next Http handler in the queue
   */
  intercept(req: HttpRequest<any>, next: HttpHandler) {

    //Check that the request is to Contentful
    const isContentfulReq = req.url.indexOf('.contentful.com') > -1;

    //Check that a user exists
    if (isContentfulReq) {
      this.logger.log("AuthInterceptor: Intercepting http " + req.method + " req to '" + req.url + "'");

      // Get the panelServicesToken from the AuthService.
      const authToken = environment.KEYS.contentful;
      if (authToken) {
        // Clone the request and add the new header
        const authReq = req.clone({
          headers: req.headers.set(this.tokenName, 'Bearer ' + authToken)
        });
        // send cloned request with header to the next handler.
        return next.handle(authReq);
      }
      else {
        this.logger.error("AuthInterceptor: User has no authToken")
      }
    }
    //By default, pass the req onto the next handler
    return next.handle(req);

  }
}
