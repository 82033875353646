import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { SimplePage } from 'src/app/models';
import { environment } from 'src/environments/environment';
import { ArticleService } from '../services';
import { ContentfulApiService } from 'src/app/services/contentful/contentful-api.service';

@Injectable({
  providedIn: 'root'
})
export class ContactResolver implements Resolve<SimplePage> {

  constructor(
    private contentful: ContentfulApiService,
    @Inject(PLATFORM_ID) public platformId: any,
    private articleService: ArticleService,
    private transferState: TransferState
  ) { }

  resolve(): Observable<SimplePage> {
    const key = makeStateKey<SimplePage>(environment.OPTIONS.contentful.contactPageId);
    const transferState = this.transferState.get(key, null);
    if (transferState) {
      return of(transferState);
    }
    return this.contentful.getEntry<SimplePage>(environment.OPTIONS.contentful.contactPageId).pipe(
      first(),
      tap(page => {
        if (isPlatformServer(this.platformId)) {
          this.transferState.set(key, this.articleService.stripObject(page));
        }
      })
    );
  }
}
