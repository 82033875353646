<header class="header" [class.fixed]="showSearchPanel || showMenuPanel">
  <div class="container">
    <div class="inner">
      <div class="logo">
        <a [routerLink]="['/']">
          <img src="/assets/img/data-rich-logo.svg" />
          <span class="sr-only">DataRich</span>
        </a>
      </div>
      <div class="search-bar" [class.show]="showSearchPanel" *browserOnly>
        <div class="gcse-search"></div>
      </div>

      <button [class.close-button]="showSearchPanel" class="search-button" type="button" (click)="toggleSearchPanel($event)"></button>
      <button [class.close-button]="showMenuPanel" class="menu-button" type="button" (click)="toggleMenuPanel($event)"></button>
    </div>
  </div>
  <app-mega-menu class="mega-menu-component" [class.show]="showMenuPanel"></app-mega-menu>
</header>
