import { Component } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { SeoService } from 'src/app/services';
import { RouteData } from '../models';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  private subs: Subscription[] = [];
  prevData: RouteData;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private seoService: SeoService
  ) {
    this.subs.push(
      this.router.events.pipe(
        filter((e: Event): e is NavigationEnd => e instanceof NavigationEnd),
        map(() => {
          let route: ActivatedRoute = this.route;
          this.prevData = route.snapshot.data;
          while (route.firstChild) {
            if (route.snapshot.data && Object.keys(route.snapshot.data).length) {
              this.prevData = route.snapshot.data;
            }
            route = route.firstChild;
          }
          return route.snapshot.data && Object.keys(route.snapshot.data).length ? route.snapshot.data : this.prevData;
        }),
      ).subscribe((data: RouteData) => this.seoService.setFromRouteData(data))
    );
  }
}
