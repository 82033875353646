import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import { PillarPage, ContentType } from 'src/app/models';
import { PillarService, SeoService, ContentfulService } from 'src/app/services';

@Injectable({
  providedIn: 'root'
})
export class PillarPageResolver implements Resolve<PillarPage> {

  constructor(
    private contentful: ContentfulService,
    private seoService: SeoService,
    private pillarService: PillarService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<PillarPage> {
    const slug = route.params.pillar;

    return this.pillarService.getAll().pipe(
      first(),
      map((pages: PillarPage[]) => {
        const page = pages.find(p => p.slug === slug);
        if (page) {
          this.pillarService.setCurrentPillar(page);
          this.seoService.setTitle(page.metaTitle || (page.title + " | DataRich"));
          if (page.metaDescription) {
            this.seoService.setMetaTag("description", page.metaDescription);
          }
          if (page.metaKeywords) {
            this.seoService.setMetaTag("keywords", page.metaKeywords);
          }
          return page;
        }
        return null;
      })
    );
  }
}
