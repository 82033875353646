import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Locale } from 'src/app/models';
import { ContentfulService } from 'src/app/services';

@Component({
  selector: 'layout-main',
  templateUrl: './layout-main.component.html'
})
export class LayoutMainComponent implements OnInit, OnDestroy {

  private subs: Subscription[] = [];

  constructor() {}

  ngOnInit() {}

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
}
