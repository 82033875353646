import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID, QueryList, Renderer2, ViewChildren, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Event as RouterEvent, NavigationEnd, Router, UrlTree } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ContentfulService, LoggerService } from 'src/app/services';
import { isDefined } from '../../../models';
import { MegaMenuComponent } from '../mega-menu/mega-menu.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy {

  private subs: Subscription[] = [];
  private closePanelsTimer: any;

  articleList: any;

  showSearchPanel: boolean;
  showMenuPanel: boolean;

  isHandset: boolean;
  isHandset$: Observable<BreakpointState> = this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small]);

  openNavMenu: string;
  openNavButton: string;

  private buttonLeaveTimers: { [slug: string]: any } = {};
  private menuLeaveTimers: { [slug: string]: any } = {};

  readonly menuCls = "menu-is-open";

  articleCounts: { [slug: string]: number } = {};
  private setArticleCountTimers: { [slug: string]: any } = {};

  triggers: MatMenuTrigger[];
  @ViewChild(MegaMenuComponent) megaMenu: MegaMenuComponent;
  @ViewChildren(MatMenuTrigger) set _triggers(triggers: QueryList<MatMenuTrigger>) {
    if (triggers) {
      this.triggers = triggers.toArray();
    }
  }

  constructor(
    private router: Router,
    private contentful: ContentfulService,
    private logger: LoggerService,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private document: Document,
    private breakpointObserver: BreakpointObserver,
  ) { }

  setArticleCount(count: number = 0, parent: string) {
    if (this.setArticleCountTimers[parent]) {
      clearTimeout(this.setArticleCountTimers[parent]);
    }
    this.setArticleCountTimers[parent] = setTimeout(() => {
      if (!isDefined(this.articleCounts[parent]) || this.articleCounts[parent] !== count) {
        this.articleCounts[parent] = count;
      }
    }, 10);
  }

  private closeAllMenus(trigger?: MatMenuTrigger) {
    this.triggers.forEach(t => {
      if (trigger && t !== trigger) {
        t.closeMenu();
      }
    });
  }

  buttonEnter(slug: string) {
    if (this.buttonLeaveTimers[slug]) {
      clearTimeout(this.buttonLeaveTimers[slug]);
    }
    if (this.openNavButton !== slug) {
      this.openNavButton = slug;
    }
  }

  buttonLeave(slug: string) {
    if (this.buttonLeaveTimers[slug]) {
      clearTimeout(this.buttonLeaveTimers[slug]);
    }
    this.buttonLeaveTimers[slug] = setTimeout(() => {
      if (this.openNavButton === slug) {
        this.openNavButton = "";
      }
    }, 100);
  }

  menuEnter(slug: string) {
    if (this.menuLeaveTimers[slug]) {
      clearTimeout(this.menuLeaveTimers[slug]);
    }
    if (this.openNavMenu !== slug) {
      this.openNavMenu = slug;
    }
  }

  menuLeave(slug: string) {
    if (this.menuLeaveTimers[slug]) {
      clearTimeout(this.menuLeaveTimers[slug]);
    }
    this.menuLeaveTimers[slug] = setTimeout(() => {
      if (this.openNavMenu === slug) {
        this.openNavMenu = "";
      }
    }, 80);
  }

  isCurrentPage(url: string[]) {
    const urlTree: UrlTree = this.router.createUrlTree(url);
    return this.router.isActive(urlTree, false);
  }

  linkClick(slug: string, event?: Event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.router.navigate(['/', slug]).then(() => {
      this.closePanels();
    });
  }

  toggleSearchPanel(event?: Event) {
    if (event) {
      event.preventDefault();
    }
    this.logger.log("SEARCH");
    if (this.showSearchPanel) {
      this.closePanels();
    }
    else {
      this.closePanels();
      this.showSearchPanel = true;
      this.renderer.addClass(this.document.body, this.menuCls);
    }
  }

  @HostListener("window:resize")
  closePanelsOnThrottle() {
    if (window.innerWidth < 768) {
      if (this.closePanelsTimer) {
        clearTimeout(this.closePanelsTimer);
      }
      this.closePanelsTimer = setTimeout(() => {
        this.closePanels();
      }, 100);
    }
  }

  closePanels() {
    if (this.closePanelsTimer) {
      clearTimeout(this.closePanelsTimer);
    }
    this.showSearchPanel = false;
    this.showMenuPanel = false;
    this.openNavMenu = "";
    if (this.megaMenu) {
      this.megaMenu.clearSelected();
    }
    this.renderer.removeClass(this.document.body, this.menuCls);
  }

  toggleMenuPanel(event?: Event) {
    if (event) {
      event.preventDefault();
    }
    this.logger.log("MENU");
    if (this.showMenuPanel) {
      this.closePanels();
    }
    else {
      this.showMenuPanel = true;
      this.renderer.addClass(this.document.body, this.menuCls);
      this.showSearchPanel = false;
    }
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.subs.push(
        this.isHandset$.subscribe((result: BreakpointState) => this.isHandset = result.matches),
        this.router.events.pipe(
          filter((e: RouterEvent): e is NavigationEnd => e instanceof NavigationEnd),
        ).subscribe(() => (this.closePanels()))
      );
    }
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
}
