import { isPlatformBrowser } from '@angular/common';
import { Component, ContentChild, Inject, Input, OnDestroy, OnInit, PLATFORM_ID, TemplateRef, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { uniqBy } from 'lodash';
import { of, Subscription, Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Article, PillarPage, SkeletonRow } from 'src/app/models';
import { ArticleService, LoggerService, PillarService, SkeletonService } from 'src/app/services';

@Component({
  selector: 'app-articles-list',
  templateUrl: './articles-list.component.html'
})
export class ArticlesListComponent implements OnInit, OnDestroy {

  private subs: Subscription[] = [];
  private allArticles: Article[] = [];
  private setTotalSub: Subscription;

  articles: Article[] = [];
  total: number;
  loading = true;

  allParentPages: { [id: string]: PillarPage } = {};
  loadingMore: boolean;

  articleIds: string[];
  @Input('articleIds') set _articles(articleIds: string[]) {
    if (articleIds) {
      this.articleIds = articleIds;
      if (this.allArticles.length) {
        this.getNextPage();
      }
    }
  }

  hiddenArticleIds: string[];
  @Input('hiddenArticles') set _hiddenArticles(hiddenArticleIds: string[]) {
    if (hiddenArticleIds) {
      this.hiddenArticleIds = hiddenArticleIds;
      if (this.allArticles.length) {
        this.setHidden();
      }
    }
  }

  limit: number = 6;
  @Input('limit') set _limit(limit: number) {
    if (limit) {
      this.limit = limit;
    }
  }

  parentPage: PillarPage;
  @Input('parentPage') set _parentPage(parentPage: PillarPage) {
    if (parentPage) {
      this.parentPage = parentPage;
      this.allArticles = [];
      this.articles = [];

      if (this.initArticles) {
        this.getArticles();
      }
    }
  }

  moreButtonLabel: string;
  @Input('moreButtonLabel') set _moreButtonLabel(moreButtonLabel: string) {
    if (moreButtonLabel) {
      this.moreButtonLabel = moreButtonLabel;
    }
  }

  @ContentChild('template', { static: true }) template: TemplateRef<any>;
  @Input('template') set _template(ref: TemplateRef<any>) {
    this.template = ref;
  }
  hideMoreButton: boolean;
  @Input('hideMoreButton') set _hideMoreButton(hideMoreButton: boolean) {
    this.hideMoreButton = hideMoreButton;
  }

  hideEmptyMessage: boolean;
  @Input('hideEmptyMessage') set _hideEmptyMessage(hideEmptyMessage: boolean) {
    this.hideEmptyMessage = hideEmptyMessage;
  }

  @Output("articleCount") articleCount: EventEmitter<number> = new EventEmitter();

  public skeletonLayout: SkeletonRow[] = [
    this.skeletonService.getRectangles(3)
  ];

  initArticles: boolean;
  isBrowser: boolean = isPlatformBrowser(this.platformId);

  constructor(
    private skeletonService: SkeletonService,
    private route: ActivatedRoute,
    private pillarService: PillarService,
    private logger: LoggerService,
    @Inject(PLATFORM_ID) private platformId: any,
    private articleService: ArticleService
  ) { }

  loadMore(event?: Event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.loadingMore = true;
    // Local
    if (this.articles.length + this.limit <= this.allArticles.length) {
      this.getNextPage();
    }
    else {
      this.getArticles();
    }
  }

  filterArticlesByParent() {
    if (this.parentPage && this.allArticles.length) {
      this.allArticles = this.allArticles.filter(a => a.pillar && a.pillar.sys.id === this.parentPage.id);
      this.setHidden();
    }
    this.getNextPage();
  }

  getNextPage() {
    if (this.articleIds && this.articleIds.length) {
      this.setArticles(this.allArticles.filter(article => this.articleIds.indexOf(article.id) > -1), true);
    }
    else {
      this.setArticles(this.allArticles.slice(this.articles.length, this.articles.length + this.limit));
    }
    this.loadingMore = false;
  }

  setTotal() {
    if (this.setTotalSub) {
      this.setTotalSub.unsubscribe();
    }
    if (this.parentPage) {
    }
    this.subs.push(
      this.setTotalSub = this.articleService.getLog(this.parentPage ? "__" + this.parentPage.id : "").subscribe(
        resp_log => {
          if (resp_log) {
            this.total = resp_log.total || this.allArticles.length || 100;
          }
          else {
            this.total = this.total || this.allArticles.length || 100;
          }
        },
        err => {
          this.total = this.total || this.allArticles.length || 100;
        }
      )
    );
  }

  setHidden() {
    if (this.hiddenArticleIds && this.hiddenArticleIds.length) {
      this.allArticles = this.allArticles.filter(a => this.hiddenArticleIds.indexOf(a.id) === -1);
    }
  }

  getParents() {
    this.subs.push(
      this.pillarService.getAll()
        .pipe(catchError(() => of([])))
        .subscribe(
          pillarPages => {
            this.allParentPages = pillarPages.reduce((list, p) => {
              list[p.id] = p;
              return list;
            }, {} as any);
            this.loading = false;
          })
    );
  }

  setArticles(articles: Article[], reset?: boolean) {
    if (reset) {
      this.articles = [];
    }
    this.articles.push(...articles);
    this.articleCount.emit(this.articles.length);
  }

  getArticles() {
    if (isPlatformBrowser(this.platformId)) {
      this.parentPage ? this.getArticlesByParent() : this.getAllArticles();

      if (!this.initArticles) {
        this.initArticles = true;
      }
    }
  }

  getArticlesByParent() {
    if (this.parentPage) {
      this.subs.push(
        this.articleService.getByPillar(this.parentPage.id, this.allArticles.length, !this.initArticles).subscribe(articles => {
          this.allArticles = uniqBy([...this.allArticles, ...articles], "id");
          this.setHidden();
          this.getNextPage();
          this.setTotal();
        })
      );
    }
  }

  getAllArticles() {
    this.subs.push(
      this.articleService.getAll(true, this.allArticles.length)
        .pipe(catchError(() => of([])))
        .subscribe(
          articles => {
            this.allArticles = uniqBy([...this.allArticles, ...articles], "id");
            this.setHidden();
            this.getNextPage();
            this.setTotal();
            this.loading = false;
            this.loadingMore = false;
          },
          () => {
            this.loading = false;
          }
        )
    );
  }

  getFirstPage(route: ActivatedRouteSnapshot = this.route.snapshot) {
    if (isPlatformBrowser(this.platformId) && this.parentPage) {
      this.getArticles();
    }
    else {
      this.setArticles([], true);
      this.allArticles = uniqBy([...this.allArticles, ...route.data['articles']], "id");
      this.setHidden();
      this.setTotal();
      this.filterArticlesByParent();
      if (this.articles.length < this.limit) {
        this.setArticles([], true);
        this.getArticles();
      }
      else {
        this.initArticles = true;
      }
    }
    this.loading = false;
    this.loadingMore = false;
  }

  ngOnInit() {
    this.subs.push(
      // Subscribe to route.params so we get an update everytime the route changes,
      // but the data we actually want is in route.data
      this.route.params.pipe(switchMap(() => this.route.data)).subscribe((data) => {
        if (data["articles"]) {
          this.getFirstPage();
        }
        else if (isPlatformBrowser(this.platformId)) {
          this.getArticles();
        }
        this.getParents();
      }),
    );
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

}
