<div class="breadcrumbs-wrapper">
  <div class="container inner">
    <!--<h3 class="title" *ngIf="currentPage?.name || title">
      {{currentPage?.name || title}}
    </h3>-->
    <ul class="breadcrumbs">
      <ng-container *ngFor="let page of pages; index as i">
        <li>
          <span *ngIf="i === (pages.length - 1); else breadcrumbTemplate">{{page.name}}</span>
          <ng-template #breadcrumbTemplate>
            <a [routerLink]="page.link">{{page.name}}</a>
          </ng-template>
        </li>
      </ng-container>
    </ul>
  </div>
</div>
