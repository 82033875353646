import { Injectable } from '@angular/core';
import { AssetCollection, ContentfulClientApi, createClient, EntryCollection, RichTextDataTarget, Asset as ContentfulAsset } from 'contentful';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Asset, ContentfulResponse, ContentQuery, ContentType } from 'src/app/models';
import { environment } from 'src/environments/environment';
import { ApiService } from '../general';
import { LoggerService } from '../general/logger.service';
import { LocaleService } from './locale.service';

const API_URL: string = environment.URLS.contentful + '/spaces/' + environment.OPTIONS.contentful.spaceID + '/environments/' + environment.OPTIONS.contentful.env + '/';

@Injectable({
  providedIn: 'root'
})
export class ContentfulService {

  private client: ContentfulClientApi = createClient({
    space: environment.OPTIONS.contentful.spaceID,
    environment: environment.OPTIONS.contentful.env,
    accessToken: environment.KEYS.contentful,
    host: environment.URLS.contentful.replace("https://", "")
  });

  queryLog: { [content_type: string]: ContentfulResponse } = {};

  constructor(
    private logger: LoggerService,
    private api: ApiService,
    private localeService: LocaleService,
  ) { }

  // Entries

  getEntry<T = any>(id: string, query?: ContentQuery): Observable<T> {
    return from(
      this.client.getEntry<T>(id, query || {}).then((entry) => {
        if (entry && entry.fields) {
          return {
            id: entry.sys.id,
            sys: entry.sys,
            ...entry.fields,
          } as T;
        }
      })
    );
  }

  getEntries<T = any>(query: ContentQuery = {}, logName?: string) {
    return from(
      this.client
        .getEntries<T>({
          ...query,
          locale: query.locale || this.localeService.locale,
        })
        .then((resp: EntryCollection<T>) => {
          if (resp) {
            if (query.content_type) {
              this.queryLog[logName || query.content_type] = resp as any;
            }
            const base: any = {};
            if (resp.includes) {
              base.includes = resp.includes;
            }
            return (resp.items || []).map(entry => ({
              ...base,
              id: entry.sys.id,
              sys: entry.sys,
              ...entry.fields,
            } as T));
          }
          return [] as T[];
        })
        .catch((err) => {
          this.logger.warn("An error occurred: " + err && err.message);
          return [] as T[];
        })
    );
  }

  getEntryByField<T = any>(fieldName: string, fieldValue: string, type: ContentType) {
    return this.getEntries<T>({
      ['fields.' + fieldName]: fieldValue,
      'content_type': type,
      limit: 1
    }).pipe(map(arr => arr && arr[0] as T));
  }

  getEntriesByType<T = any>(type: ContentType, orderBy?: string, logName?: string): Observable<T[]>;
  getEntriesByType<T = any>(type: ContentType, query?: ContentQuery, logName?: string): Observable<T[]>;
  getEntriesByType<T = any>(...args: any[]): Observable<T[]> {
    const type: ContentType = args[0];
    const query = args[1];
    const logName = args[2];
    if (query && typeof query === "string") {
      return this.getEntries<T>({ 'content_type': type, order: 'fields.' + query }, logName);
    }
    return this.getEntries<T>({
      'content_type': type,
      order: 'sys.updatedAt',
      ...(query || {})
    }, logName);
  }

  // Assets

  getAsset(id: string): Observable<Asset> {
    return from(
      this.client.getAsset(id)
        .then((resp: ContentfulAsset) => {
          return resp ? {
            ...resp.fields,
            metadata: resp.metadata,
            id: resp.sys.id
          } as Asset : null;
        })
        .catch((err) => {
          this.logger.warn("An error occurred: " + err && err.message);
          return null;
        })
    );
  }

  getAssets(): Observable<Asset[]> {
    return from(
      this.client.getAssets({ limit: 1000 })
        .then((resp: AssetCollection) => {
          return (resp && resp.items || []).map(item => ({
            ...item.fields,
            metadata: item.metadata,
            id: item.sys.id
          } as Asset));
        })
        .catch((err) => {
          this.logger.warn("An error occurred: " + err && err.message);
          return [] as Asset[];
        })
    );
  }

  retrieveLinkTarget(link: RichTextDataTarget) {
    if (link.sys.linkType === "Asset") {
      return this.getAsset(link.sys.id);
    }
    return this.getEntry(link.sys.id);
  }

}
