import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit, OnDestroy {

  private subs: Subscription[] = [];
  year: number = new Date().getFullYear();

  form: FormGroup = this.fb.group({
    email: ['', Validators.email]
  });

  constructor(
    private fb: FormBuilder
  ) { }

  saveEmailAddress

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
}
