import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { Page } from 'src/app/models';
import { environment } from 'src/environments/environment';
import { ArticleService, SeoService } from '../services';
import { ContentfulApiService } from '../services/contentful/contentful-api.service';

@Injectable({
  providedIn: 'root'
})
export class HomeResolver implements Resolve<Page> {

  constructor(
    private contentful: ContentfulApiService,
    @Inject(PLATFORM_ID) public platformId: any,
    private seoService: SeoService,
    private articleService: ArticleService,
    private transferState: TransferState
  ) { }

  resolve(): Observable<Page> {
    const key = makeStateKey<Page>(environment.OPTIONS.contentful.homePageId);
    const transferState = this.transferState.get(key, null);
    if (transferState) {
      return of(transferState);
    }
    return this.contentful.getEntry<Page>(environment.OPTIONS.contentful.homePageId).pipe(
      first(),
      tap(page => {
        if (page) {
          this.seoService.setTitle(page.metaTitle || (page.title + " | DataRich"));
          if (page.metaDescription) {
            this.seoService.setMetaTag("description", page.metaDescription);
          }
          if (page.metaKeywords) {
            this.seoService.setMetaTag("keywords", page.metaKeywords);
          }
          if (isPlatformServer(this.platformId)) {
            this.transferState.set(key, this.articleService.stripObject(page));
          }
        }
      })
    );
  }
}
