import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Article, ContentType, Link, PillarPage, SkeletonRow } from 'src/app/models';
import { ArticleService, ContentfulService, LoggerService, PillarService, SkeletonService } from 'src/app/services';


@Component({
  selector: 'app-article',
  templateUrl: './article.component.html'
})
export class ArticleComponent implements OnInit, OnDestroy {

  private subs: Subscription[] = [];
  private slug: string;
  private parent: string;
  content: Article;
  redirectTimer: any;
  loading: boolean;

  public parentPage: PillarPage;

  skeletonLoader: SkeletonRow[] = [
    this.skeletonService.getTitle({ width: 350 }),
    this.skeletonService.getSubtitle(),
    this.skeletonService.getTextBlock(5),
    this.skeletonService.getRectangles(1, 80, 300, { top: 10, bottom: 15, left: 10 }),
    this.skeletonService.getTextBlock(3, { top: 0 }),
    this.skeletonService.getButtons(2, { top: 30, left: 24.5 })
  ];

  breadcrumbTrail: Link[] = [];

  public isHandset$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.Tablet])
    .pipe(map((result: BreakpointState) => result.matches));
  public isHandset: boolean;

  constructor(
    private pillarService: PillarService,
    private articleService: ArticleService,
    private logger: LoggerService,
    private contentful: ContentfulService,
    private router: Router,
    private route: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private skeletonService: SkeletonService,
  ) { }

  setBreadcrumb() {
    this.breadcrumbTrail = [];
    this.breadcrumbTrail.push(
      {
        link: '/' + this.parentPage.slug,
        name: this.parentPage.shortTitle || this.parentPage.title
      },
      {
        link: '/' + this.content.slug,
        name: this.content.shortTitle || this.content.title
      });
  }

  getPage() {
    if (this.slug) {
      this.contentful.getEntryByField('slug', this.slug, ContentType.ARTICLE).subscribe(article => {
        if (article) {
          this.setContent(article);
        }
      })
    }
    else {
      this.redirect();
    }
  }

  setContent(article: Article) {
    if (this.redirectTimer) {
      clearTimeout(this.redirectTimer);
    }
    this.content = article;
    this.logger.log("Article: ", this.content);
    this.articleService.setCurrentArticle(this.content);
    this.setParentPage();
  }

  getPageForRoute() {
    const article = this.route.snapshot.data['article'];
    if (article) {
      this.setContent(article);
    }
    if (!this.content || !this.content.body) {
      this.getPage();
    }
  }

  setParentPage() {
    if (this.content && this.content.pillar && this.content.pillar.sys && this.content.pillar.sys.id) {
      this.subs.push(
        this.pillarService.get(this.content.pillar.sys.id, 'id').subscribe(
          parentPage => {
            if (parentPage) {
              this.parentPage = parentPage;
              this.pillarService.setCurrentPillar(parentPage);
              this.setBreadcrumb();
            }
            this.loading = false;
          },
          err => {
            this.loading = false;
          }
        )
      );
    }
  }

  redirect() {
    this.logger.error("Content not found");
    if (this.redirectTimer) {
      clearTimeout(this.redirectTimer);
    }
    this.redirectTimer = setTimeout(() => {
      this.router.navigate(['/' + (this.parent || '')], {
        queryParamsHandling: 'preserve'
      });
    }, 2000);
  }

  ngOnInit() {
    this.loading = true;
    this.subs.push(
      // Subscribe to route.params so we get an update everytime the route changes,
      // but the data we actually want is in route.data
      this.route.params.pipe(switchMap((params) => {
        this.parent = params["pillar"];
        this.slug = params["article"];
        return this.route.data;
      })).subscribe((data) => {
        if (data['article']) {
          if (this.redirectTimer) {
            clearTimeout(this.redirectTimer);
          }
          this.getPageForRoute();
        }
        else {
          this.getPage();
        }
      }),
      this.isHandset$.subscribe(isHandset => {
        if (this.isHandset !== isHandset) {
          this.isHandset = !!isHandset;
        }
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

}
