import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ArticleComponent, ContactComponent, HomeComponent, PillarPageComponent, PrivacyComponent } from './components/content/content.module';
import { LayoutMainComponent } from './components/layout/layout-main/layout-main.component';
import { ArticleResolver, ContactResolver, HomeResolver, PillarPageResolver, PillarPagesResolver, PrivacyResolver, ArticlesResolver } from './resolvers';

const routes: Routes = [

  // Public site routes go here
  {
    path: '',
    component: LayoutMainComponent,
    resolve: {
      articles: ArticlesResolver,
      pillarPages: PillarPagesResolver,
    },
    children: [
      {
        path: '',
        component: HomeComponent,
        resolve: {
          homePage: HomeResolver
        }
      },
      {
        path: 'contact',
        component: ContactComponent,
        resolve: {
          contactPage: ContactResolver
        }
      },
      {
        path: 'privacy',
        component: PrivacyComponent,
        resolve: {
          privacyPage: PrivacyResolver
        }
      },
      {
        path: ':pillar',
        component: PillarPageComponent,
        resolve: {
          pillarPage: PillarPageResolver,
        }
      },
      {
        path: ':pillar/:article',
        component: ArticleComponent,
        resolve: {
          article: ArticleResolver,
        }
      },
    ]
  },

  // Catch all
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    initialNavigation: 'enabledBlocking'
  })], // debug: { enableTracing: true }
  exports: [RouterModule]
})
export class AppRoutingModule { }
