import { FormGroup, NgForm } from '@angular/forms';
export * from './base.models';

export interface DataItem<T = any> {
  value: string | number;
  viewValue: string;
  data?: T;
  id?: string;
}
export interface FormSelectData<T = any> extends DataItem { }

export interface Form {
  save: () => any;
  form: FormGroup | NgForm;
}


export interface UploadedFile {
  projectId?: string;
  organizationId?: string;
  uploadedAt?: number;
  uploadedBy?: string;
  downloadURL?: string;
  name?: string;
  filetype?: string;
  path?: string;
}

export interface UploadTask extends UploadedFile {
  progress?: number;
  complete?: boolean;
  failed?: boolean;
}

export interface ComplexFilterValue<T = string> {

  // For numbers matches only
  $gt?: number;
  $lt?: number;

  // For any matches
  $eq?: T;
  $in?: T[];
  $ex?: T[];

  // For string matches only
  $regex?: string;
  $options?: 'i'|'g'|'ig';
}


export interface PagerConfig {
  items?: number;
  page?: number;
  pages?: number;
  count?: number;
  sort?: string;
  reverse?: boolean;
  allowPolling?: boolean;
}

export interface PagedHttpResponse<T = any, F = any> extends PagerConfig {
  data?: T[];
  filters?: F;
}

export interface RespondentList {
  id: string;
  created_at: string;
  name: string;
  file_url: string;
}

export interface RouteData {
  title?: string;
  url?: string;
  tags?: { [key: string]: string };
}




//Functions

export function isDefined(item: any) {
  return typeof item !== 'undefined';
}

export function trackByIndexFn(index, item) {
  return index;
}

export const FRIENDLY_DATE_FORMAT = 'E, MMM d, yyyy hh:mma';
export const SHORT_DATE_FORMAT = 'M/d/yy h:mma';
export const DAY_DATE_FORMAT = 'M/d/yy';

export function extendObj<T = any>(...args: any[]): T;
export function extendObj<T = any>(deep: boolean, ...args: any[]): T;
export function extendObj<T = any>(...args: any[]): T {

  // Variables
  var extended = {};
  var deep = false;
  var i = 0;
  var length = arguments.length;

  // Check if a deep merge
  if (Object.prototype.toString.call(arguments[0]) === '[object Boolean]') {
    deep = arguments[0];
    i++;
  }

  // Merge the object into the extended object
  var merge = (obj) => {
    for (var prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        // If deep merge and property is an object, merge properties
        if (deep && Object.prototype.toString.call(obj[prop]) === '[object Object]') {
          extended[prop] = extendObj<T>(true, {}, obj[prop]);
        }
        else if (deep && Object.prototype.toString.call(obj[prop]) === '[object Array]') {
          extended[prop] = copyArray(obj[prop]);
        }
        else {
          extended[prop] = obj[prop];
        }
      }
    }
  };

  // Loop through each object and conduct a merge
  for (; i < length; i++) {
    var obj = arguments[i];
    merge(obj);
  }

  return extended as T;
}


export function copyArray(arr: any[]) {
  let copy = [];
  for (let i in arr) {

    if (Object.prototype.toString.call(arr[i]) === '[object Object]') {
      copy[i] = extendObj(true, {}, arr[i]);
    }
    else if (Object.prototype.toString.call(arr[i]) === '[object Array]') {
      copy[i] = copyArray(arr[i]);
    }
    else {
      copy[i] = arr[i];
    }
  }
  return copy;
}
