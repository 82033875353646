import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { orderBy } from 'lodash';
import { ReplaySubject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Article, ContentType } from 'src/app/models';
import { CacheService } from '../general';
import { GENERAL_STORE_NAME } from '../general/cache.service';
import { LoggerService } from '../general/logger.service';
import { ContentfulService } from './contentful.service';
import { EntryService } from './entry.service';
import { LocaleService } from './locale.service';

@Injectable({
  providedIn: 'root'
})
export class ArticleService extends EntryService<Article> {

  listBy = "slug";
  order = "date";
  reverse = true;
  limit = 100;
  getByPillar$: { [pillarId: string]: ReplaySubject<Article[]> } = {};

  private currentArticle$: ReplaySubject<Article> = new ReplaySubject(1);
  get currentArticle() {
    return this.currentArticle$.asObservable();
  }

  constructor(
    contentful: ContentfulService,
    localeService: LocaleService,
    cache: CacheService,
    logger: LoggerService,
    transferState: TransferState,
    @Inject(PLATFORM_ID) public platformId: any,
  ) {
    super(ContentType.ARTICLE, contentful, localeService, logger, cache, platformId, transferState, { order: "fields.date", limit: 5, skip: 0 });
  }

  setCurrentArticle(article: Article) {
    this.currentArticle$.next(article);
  }

  private init() {
    this.getAll().subscribe();
  }

  getByPillar(pillarId: string, skip: number = 0, force?: boolean) {
    const id = pillarId + "_skip-" + skip + "_orderBy-" + this.order;
    if (force || !this.getByPillar$[id]) {

      this.getByPillar$[id] = this.getByPillar$[id] || new ReplaySubject(1);

      const pageKeyName = this.type + "__" + pillarId + "_GET";
      const pageKey = makeStateKey<Article[]>(pageKeyName);

      const logKeyName = this.type + "__" + pillarId + "_LOG";
      const logKey = makeStateKey<any>(logKeyName);

      if (this.isPlatformBrowser) {
        let logState = this.transferState.get(logKey, null);
        if (logState) {
          this.cache.processContentResponse(GENERAL_STORE_NAME as any, [logState]);
          this.transferState.remove(logKey);
        }

        const pageState = this.transferState.get(pageKey, null);
        if (pageState) {
          this.getByPillar$[id].next(pageState);
          if (this.isPlatformBrowser) {
            this.cache.processContentResponse(this.type, pageState, this.listBy);
            this.transferState.remove(pageKey);
          }
          this.logger.groupEnd();
          return this.getByPillar$[id].asObservable();
        }
      }
      const fresh = () => this.contentful.getEntriesByType<Article>(this.type,
        {
          skip: skip,
          limit: 5,
          order: (this.reverse ? '-' : '') + (this.order ? 'fields.' + this.order : this.defaults.order),
          ['fields.pillar.sys.id']: pillarId
        }, logKeyName);
      const stale = () => this.cache.getItems<Article>(this.type).pipe(map((articles) => {
        const filtered = orderBy(articles.filter((article) => article.pillar.sys.id === pillarId), (this.order || this.defaults.order), (this.reverse ? 'desc' : 'asc')).slice(skip);
        if (!filtered || !filtered.length) {
          throw new Error("No articles for pillar with ID " + pillarId);
        }
        return filtered;
      }));
      const obs = this.isPlatformServer || force ? fresh() : stale();

      return obs.pipe(
        catchError(() => fresh()),
        tap(articles => {
          this.getByPillar$[id].next(articles);
          let resp_log = this.contentful.queryLog[logKeyName] || null;
          const log = {
            id: logKeyName,
            skip: resp_log ? resp_log.skip : 0,
            limit: resp_log ? resp_log.limit : 0,
            total: resp_log ? resp_log.total : 0,
          } as any;
          if (this.isPlatformBrowser) {
            if (resp_log) {
              this.cache.processContentResponse(GENERAL_STORE_NAME as any, [log]);
            }
            this.cache.processContentResponse(this.type, articles, this.listBy);
          }
          if (this.isPlatformServer && articles.length) {
            this.transferState.set(pageKey, this.mapToTransferState(articles));
            if (resp_log) {
              this.transferState.set(logKey, log);
            }
          }
        })
      );
    }
    return this.getByPillar$[id].asObservable();
  }


}
