import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { Article, ContentType } from 'src/app/models';
import { ArticleService, ContentfulService, SeoService } from 'src/app/services';

@Injectable({
  providedIn: 'root'
})
export class ArticleResolver implements Resolve<Article> {

  constructor(
    private contentful: ContentfulService,
    private articleService: ArticleService,
    private seoService: SeoService,
  ) { }

  resolve(route: ActivatedRouteSnapshot): Observable<Article> {
    const slug = route.params.article;

    return this.contentful.getEntryByField('slug', slug, ContentType.ARTICLE).pipe(
      first(),
      tap((article: Article) => {
        if (article) {
          this.seoService.setTitle(article.metaTitle || (article.title + " | DataRich"));
          if (article.metaDescription) {
            this.seoService.setMetaTag("description", article.metaDescription);
          }
          if (article.metaKeywords) {
            this.seoService.setMetaTag("keywords", article.metaKeywords);
          }
          this.articleService.setCurrentArticle(article);
        }
      })
    );
  }
}
