import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { isArray } from 'lodash';
import { Observable, of, ReplaySubject, Subscription, throwError } from 'rxjs';
import { catchError, first, map, switchMap, take, tap } from 'rxjs/operators';
import { ContentfulPagedResponse, ContentQuery, ContentType, DEFAULT_LOCALE, Locale } from 'src/app/models';
import { CacheService } from '../general';
import { GENERAL_STORE_NAME } from '../general/cache.service';
import { LoggerService } from '../general/logger.service';
import { ContentfulService } from './contentful.service';
import { LocaleService } from './locale.service';

declare const global: any;

export abstract class EntryService<T = any> {

  private localeSub: Subscription;
  private getAllInitiated: boolean;
  readonly isPlatformBrowser = isPlatformBrowser(this.platformId);
  readonly isPlatformServer = isPlatformServer(this.platformId);

  private entries$: ReplaySubject<T[]> = new ReplaySubject(1);
  private pages$: { [page: string]: ReplaySubject<T[]> } = {};
  readonly defaults = {
    limit: 100,
    order: 'sys.updatedAt',
    skip: 0
  };

  listBy: string = "id";
  order: string;
  total: number = 1000;
  limit: number;
  skip: number = 0;
  reverse: boolean;

  allEntries: T[] = [];
  allEntriesMap: { [id: string]: T } = {};

  locale: Locale = DEFAULT_LOCALE;
  get entries(): Observable<T[]> {
    return this.entries$.asObservable();
  }

  get ssrRequests(): { [id: string]: ReplaySubject<T> } {
    return (global as any).ssrRequests = (global as any).ssrRequests || {};
  }
  constructor(
    public type: ContentType,
    public contentful: ContentfulService,
    public localeService: LocaleService,
    public logger: LoggerService,
    public cache: CacheService,
    public platformId: any,
    public transferState: TransferState,
    public query: ContentQuery = {}
  ) {
    this.query.locale = this.locale;
    if (this.localeSub) {
      this.localeSub.unsubscribe()
    }
    this.query.limit = this.query.limit || this.limit || this.defaults.limit;
    this.query.skip = this.query.skip || this.skip || this.defaults.skip;
    this.query.order = this.query.order || ((this.reverse ? '-' : '') + (this.order ? 'fields.' + this.order : this.defaults.order));
    this.localeSub = this.localeService.locale$
      .pipe(
        switchMap((locale) => {
          return this.cache.localeCheck(locale);
        })
      )
      .subscribe((locale) => {
        if (locale && (!this.locale || this.locale !== locale.value)) {
          this.locale = locale.value;
          this.query.locale = this.locale;
          this.pages$ = {};
          if (this.getAllInitiated) {
            this.getAllInitiated = false;
            this.getAll().pipe(first()).subscribe();
          }
        }
      });
  }

  stripObject(obj: Object | Array<any>) {
    return Object.keys(obj).reduce((stripedObj, key) => {
      const prop = obj[key] as any;
      if (
        key !== "sys"
        && key !== "body"
        && key !== "includes"
        && key !== "space"
        && key !== "metadata"
      ) {
        if (typeof prop === "string" || typeof prop === "number" || isArray(prop)) {
          stripedObj[key] = prop;
        }
        else if (!!prop) {
          stripedObj[key] = this.stripObject(prop)
        }
      }
      if (key === "sys") {
        stripedObj["sys"] = {};
        if (prop.id) {
          stripedObj["sys"]["id"] = prop.id;
        }
        if (prop.contentType) {
          stripedObj["sys"]["contentType"] = prop.contentType;
        }
        if (prop.linkType) {
          stripedObj["sys"]["linkType"] = prop.linkType;
        }
        if (prop.updatedAt) {
          stripedObj["sys"]["updatedAt"] = prop.updatedAt;
        }
      }
      return stripedObj;
    }, {} as T)
  }

  mapToTransferState(items: T[]): any[] {
    return items.map((item: any) => {
      return {
        ...this.stripObject(item)
      }
    })
  }

  map(entries: T[]): T[] {
    return entries;
  }

  get(id: string, listBy?: string): Observable<T> {
    return this.entries.pipe(
      take(1),
      switchMap((entries) => {
        const entry = entries.find((e: any) => e[listBy || this.listBy] === id);

        if (entry) {
          return of(entry);
        }

        return this.forceGet(id).pipe(
          tap((entry) => {
            this.entries$.next([...entries, entry]);
          })
        );
      })
    );
  }

  forceGet(id: string): Observable<T> {
    return this.contentful.getEntry<T>(id).pipe(
      map((e) => {
        const entry = this.map([e])[0];
        if (entry) {
          this.cache.processContentResponse(this.type, [entry], this.listBy);
        }
        return entry;
      })
    );
  }

  getLog(prefix: string = "") {
    return this.cache.getItem(this.type + prefix + "_LOG", GENERAL_STORE_NAME);
  }

  getAll(force?: boolean, skip: number = 0, query?: any, prefix: string = "") {

    if (!this.getAllInitiated || force) {

      this.getAllInitiated = true;

      const pageKeyName = this.type + prefix + "__GET_ALL";
      const pageKey = makeStateKey<T[]>(pageKeyName);

      const logKeyName = this.type + prefix + "_LOG";
      const logKey = makeStateKey<any>(logKeyName);

      if (this.isPlatformBrowser) {
        let logState = this.transferState.get(logKey, null);
        if (logState) {
          this.cache.processContentResponse(GENERAL_STORE_NAME as any, [logState]);
          this.transferState.remove(logKey);
        }

        const pageState = this.transferState.get(pageKey, null);
        if (pageState) {
          this.entries$.next(pageState);
          if (isPlatformBrowser(this.platformId)) {
            this.cache.processContentResponse(this.type, pageState, this.listBy);
            this.transferState.remove(pageKey);
          }
          return this.entries;
        }
      }
      const fresh = () => this.contentful.getEntriesByType<T>(this.type,
        {
          skip: skip,
          limit: this.limit ? this.limit : this.defaults.limit,
          order: (this.reverse ? '-' : '') + (this.order ? 'fields.' + this.order : this.defaults.order),
          ...(query || {})
        });
      const obs = isPlatformServer(this.platformId) || force ? fresh() : this.cache.getItems<T>(this.type);

      return obs.pipe(
        catchError(() => fresh()),
        tap(items => {
          const mapped = this.map(items);
          this.entries$.next(mapped);
          let resp_log = this.contentful.queryLog[this.type] || null;
          const log = {
            id: logKeyName,
            skip: resp_log ? resp_log.skip : 0,
            limit: resp_log ? resp_log.limit : 0,
            total: resp_log ? resp_log.total : 0,
          } as any;
          if (isPlatformBrowser(this.platformId)) {
            if (resp_log) {
              this.cache.processContentResponse(GENERAL_STORE_NAME as any, [log]);
            }
            this.cache.processContentResponse(this.type, mapped, this.listBy);
          }
          if (this.isPlatformServer) {
            this.transferState.set(pageKey, this.mapToTransferState(mapped));
            if (resp_log) {
              this.transferState.set(logKey, log);
            }
          }
        })
      );
    }
    return this.entries;
  }

  getPage(next?: boolean): Observable<T[]> {
    this.query.skip = next ? this.skip + this.limit : this.skip;
    const currentPage = this.query.skip + "_" + this.query.limit + "_" + this.query.order + "__PAGED_RESPONSE";
    if (this.pages$[currentPage] && !this.pages$[currentPage].hasError) {
      return this.pages$[currentPage].asObservable();
    }
    this.pages$[currentPage] = new ReplaySubject(1);

    const key = makeStateKey<T[]>(currentPage);
    if (this.isPlatformBrowser) {
      const state = this.transferState.get(key, null);
      if (state) {
        this.pages$[currentPage].next(state);
        if (isPlatformBrowser(this.platformId)) {
          this.cache.processContentResponse(this.type, [{
            ...this.query,
            items: state,
            id: currentPage
          } as any]);
          this.transferState.remove(key);
        }
        return this.pages$[currentPage].asObservable();
      }
    }
    const fresh = () => this.contentful.getEntriesByType<T>(this.type, this.query).pipe(
      tap(page => {
        let resp_log = this.contentful.queryLog[this.type] || null;
        if (resp_log.includes) {
          delete resp_log.includes;
        }
        if (resp_log.sys) {
          delete resp_log.sys;
        }
        if (isPlatformBrowser(this.platformId)) {
          this.cache.processContentResponse<ContentfulPagedResponse<T>>(this.type, [{
            ...resp_log,
            items: page,
            id: currentPage
          } as any, {
            id: this.type + "_LOG",
            resp_log
          }]);
        }
      })
    );
    const obs = this.isPlatformServer ? fresh() : this.cache.getItem<ContentfulPagedResponse<T>>(currentPage, this.type).pipe(
      map(list => {
        const items = list.items;
        if (items) {
          this.contentful.queryLog[this.type] = list;
          return items;
        }
        throw new Error("This page does not exist");
      })
    );
    return obs.pipe(
      catchError(() => fresh()),
      tap(page => {
        const resp_log = this.contentful.queryLog[this.type] || null;
        if (resp_log) {
          this.total = resp_log.total;
          this.skip = resp_log.skip;
        }
        if (this.pages$[currentPage]) {
          this.pages$[currentPage].next(page);
        }
        if (this.isPlatformServer) {
          this.transferState.set(key, page);
        }
      }),
      catchError((err) => {
        if (this.pages$[currentPage]) {
          this.pages$[currentPage].error(err);
          delete this.pages$[currentPage];
        }
        return throwError(err);
      })
    );
  }
}
