import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { TransferState } from '@angular/platform-browser';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Link, SimplePage } from 'src/app/models';
import { LoggerService, PillarService } from 'src/app/services';
import { ContentfulApiService } from 'src/app/services/contentful/contentful-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html'
})
export class PrivacyComponent implements OnInit, OnDestroy {

  private subs: Subscription[] = [];

  content: SimplePage;

  breadcrumbTrail: Link[] = [
    { name: 'Home', link: '/' },
    { name: 'Privacy Policy', link: '/privacy' },
  ]

  constructor(
    private route: ActivatedRoute,
    private contentful: ContentfulApiService,
    private logger: LoggerService,
    @Inject(PLATFORM_ID) public platformId: any,
    private pillarService: PillarService,
    private transferState: TransferState
  ) { }

  getPage() {
    return this.contentful.getEntry<SimplePage>(environment.OPTIONS.contentful.privacyPageId).subscribe(
      page => {
        this.content = page;
      }
    );
  }

  getFirstPage(route: ActivatedRouteSnapshot = this.route.snapshot) {
    this.content = route.data['privacyPage'];
    if (!this.content || !this.content.body) {
      this.getPage();
    }
  }

  ngOnInit() {
    this.pillarService.setCurrentPillar(null);
    this.subs.push(
      this.route.params.pipe(switchMap(() => this.route.data)).subscribe((data) => {
        if (data["privacyPage"]) {
          this.getFirstPage();
        }
        else {
          this.getPage()
        }
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
}
