import { ValidatorFn, AbstractControl, FormGroup } from '@angular/forms';

export function invalidCharValidator(allowed?: boolean): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const regex = new RegExp(/[^a-z0-9\-\_]/g);
    if (!allowed && !!control.value && (<string>control.value).search(regex) > -1) {
      return { 'invalidChar': true };
    }
    return null;
  };
}

export function spaceValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (!!control.value && (<string>control.value).includes(' ')) {
      return { 'space': true };
    }
    return null;
  };
}

export function contains(search: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (!control.value || !(<string>control.value).includes(search)) {
      return { 'contains': true };
    }
    return null;
  };
}

export function oneOf(others: { [key: string]: any }): ValidatorFn {
  return (control: FormGroup): { [key: string]: boolean } | null => {
    for (let ctrl in others) {
      const c = control.get(ctrl);
      if (c.value) {
        return null;
      }
    }
    return { 'oneOf': true };
  };
}

export function jsonValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    let ret = null;
    if (control.value) {
      try {
        const json = JSON.parse(control.value);
      } catch (e) {
        ret = { 'json': true };
      }
    }
    return ret;
  };
}

export function emailStrictValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const regex = new RegExp(/^[-a-zA-Z0-9_.]+@[-a-zA-Z0-9]+\.[a-zA-Z]{2,4}$/g);
    if (!!control.value && (<string>control.value).search(regex) === -1) {
      return { 'email': true };
    }
    return null;
  };
}
