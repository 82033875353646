<div class="mega-menu-container" [class.pillar-selected]="!!_selectedPage">
  <nav class="mega-menu">
    <div class="inner">
      <div class="pillar-list-layout">
        <app-pillar-pages-list [selected]="selectedPage">
          <ng-template #template let-page>
            <a class="inner-template"
               [href]="'/' + page?.slug"
               (click)="select(page, $event)">
              <asset class="icon" [id]="page?.iconInColor?.id || page?.iconInColor?.sys?.id"></asset>
              <p>{{page?.shortTitle || page?.title}}</p>
            </a>
          </ng-template>
        </app-pillar-pages-list>
      </div>
      <div class="content">
        <div class="inner-content">
          <div class="pillar-summary"
               [class.purple]="selectedPage?.color?.toUpperCase() === '#4E1AC8'"
               [class.green]="selectedPage?.color?.toUpperCase() === '#7DC827'"
               [class.pink]="selectedPage?.color?.toUpperCase() === '#AE00E2'"
               [class.red]="selectedPage?.color?.toUpperCase() === '#F17458'">
            <a class="back-arrow" (click)="clearSelected()">
              <mat-icon>arrow_back</mat-icon>
              <h3>All sections</h3>
            </a>
            <div class="icon">
              <asset [id]="selectedPage?.iconInColor?.id || selectedPage?.iconInColor?.sys?.id"></asset>
            </div>
            <div class="summary" [routerLink]="['/', selectedPage?.slug]">
              <h3>{{selectedPage?.title}}</h3>
              <div class="mr-lg-2" [innerHTML]="(selectedPage?.summary|html)"></div>
              <a [routerLink]="['/', selectedPage?.slug]"
                 class="btn">{{'Learn More'|contentful|async}}</a>
            </div>
          </div>
          <div class="article-lists">
            <div class="featured-articles">
              <ul class="articles-container">
                <li *ngFor="let id of selectedPage?.featuredArticleIds">
                  <app-featured-article [id]="id" [baseUrl]="selectedPage?.slug"></app-featured-article>
                </li>
              </ul>
            </div>
            <div class="more-articles">
              <h4 class="mt-0 pt-0 mb-1">More articles in this section</h4>
              <app-articles-list [parentPage]="selectedPage"
                                 [hiddenArticles]="selectedPage?.featuredArticleIds"
                                 [hideMoreButton]="true"
                                 [limit]="20">
                <ng-template #template let-article>
                  <a class="article-link" [routerLink]="['/' + selectedPage?.slug, article?.slug]">
                    {{article?.shortTitle || article?.title}}
                  </a>
                </ng-template>
              </app-articles-list>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</div>
