import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { PillarPage } from 'src/app/models';
import { PillarService } from '../../../services';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-mega-menu',
  templateUrl: './mega-menu.component.html',
  host: { ngSkipHydration: 'true' }
})
export class MegaMenuComponent implements OnInit, OnDestroy {

  private subs: Subscription[] = [];

  _selectedPage: PillarPage;
  get selectedPage(): PillarPage {
    return this._selectedPage || this.currentPage || this.pillars[0];
  }
  currentPage: PillarPage;
  pillars: PillarPage[] = [];

  constructor(
    private pillarService: PillarService,
    private router: Router
  ) { }

  getCurrentPage() {
    this.pillars.find((pillar) => {
      if (this.router.url.includes(pillar.slug)) {
        this.currentPage = pillar;
        return true;
      }
      return false;
    });
  }

  select(page: PillarPage, event?: Event) {
    if (event) {
      event.preventDefault();
    }
    if (this._selectedPage && this._selectedPage.id === page.id) {
      this.router.navigate(['/', page.slug]);
    }
    this._selectedPage = page;
  }

  clearSelected() {
    this._selectedPage = null;
  }

  ngOnInit() {
    this.subs.push(
      this.pillarService.getAll()
        .subscribe(pillarPages => {
          if (pillarPages) {
            this.pillars = pillarPages;
            this.getCurrentPage();
          }
        }),
      this.router.events.pipe(
        filter((e: RouterEvent): e is NavigationEnd => e instanceof NavigationEnd),
      ).subscribe(() => {
        this.clearSelected();
        this.getCurrentPage();
      })
    );

  }

  ngOnDestroy() {
    this.clearSelected();
    this.subs.forEach(s => s.unsubscribe());
  }
}
