import { Injectable } from '@angular/core';
import { Email, EmailBase } from 'src/app/models';
import { LoggerService } from '../general/logger.service';
import { FirestoreService } from './firestore.service';


@Injectable({
  providedIn: 'root'
})
export class FirestoreEmailsService extends FirestoreService<Email, EmailBase> {

  constructor(
    public logger: LoggerService
  ) {
    super('subscribers');
  }

}
