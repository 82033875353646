<div class="banner-wrapper">
  <div class="d-flex flex-column align-items-center" *ngIf="banner?.image?.id || banner?.image?.sys?.id">
    <a (click)="trackedClick(banner)" class="banner-link cursor-pointer"
       data-entity="banner"
       [attr.data-banner-id]="banner?.id"
       [attr.data-banner-name]="banner?.name"
       [attr.data-banner-brand-name]="brand?.name">
      <asset [id]="banner?.image?.id || banner?.image?.sys?.id"></asset>
    </a>
  </div>
</div>

