import { isPlatformServer } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Link, SimplePage } from 'src/app/models';
import { LoggerService, PillarService } from 'src/app/services';
import { environment } from 'src/environments/environment';
import { ContentfulApiService } from 'src/app/services/contentful/contentful-api.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html'
})
export class ContactComponent implements OnInit, OnDestroy {

  private subs: Subscription[] = [];

  content: SimplePage;

  breadcrumbTrail: Link[] = [
    { name: 'Home', link: '/' },
    { name: 'Contact Us', link: '/contact' },
  ]

  constructor(
    private route: ActivatedRoute,
    private contentful: ContentfulApiService,
    private logger: LoggerService,
    @Inject(PLATFORM_ID) public platformId: any,
    private pillarService: PillarService,
    private transferState: TransferState
  ) { }

  getPage() {
    const key = makeStateKey<SimplePage>(environment.OPTIONS.contentful.contactPageId);
    const transferState = this.transferState.get(key, null);
    if (transferState) {
      this.content = transferState;
    }
    return this.contentful.getEntry<SimplePage>(environment.OPTIONS.contentful.contactPageId).subscribe(
      page => {
        this.content = page;
        if (isPlatformServer(this.platformId)) {
          this.transferState.set(key, page);
        }
      }
    );
  }

  getFirstPage(route: ActivatedRouteSnapshot = this.route.snapshot) {
    this.content = route.data['contactPage'];
  }

  ngOnInit() {
    this.pillarService.setCurrentPillar(null);
    this.subs.push(
      this.route.params.pipe(switchMap(() => this.route.data)).subscribe((data) => {
        if (data["contactPage"]) {
          this.getFirstPage();
        }
        else {
          this.getPage()
        }
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

}
