import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { isEqual } from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Banner, Brand, ContentfulEntry, Locale } from 'src/app/models';
import { BannerService, BrandService, LocaleService, LoggerService } from 'src/app/services';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html'
})
export class BannerComponent implements OnInit, OnDestroy {

  private subs: Subscription[] = [];
  private bannerSub: Subscription;
  private brandSub: any;

  private locale: Locale;

  layouts: string[];
  @Input('layouts') set _layouts(layouts: string | string[]) {
    layouts = typeof layouts === "string" ? [layouts] : layouts;
    if (layouts && !isEqual(this.layouts, layouts)) {
      this.layouts = layouts && layouts.length ? layouts : null;
      this.refresh();
    }
  }

  brands: ContentfulEntry<Brand>[];
  @Input('brands') set _brands(brands: ContentfulEntry<Brand>[]) {
    if (brands && !isEqual(this.brands, brands)) {
      this.brands = brands;
      this.refresh();
    }
  }

  public count: number = 1;
  @Input('count') set _count(count: number) {
    if (!isEqual(this.count, count)) {
      this.count = count || 1;
      this.refresh();
    }
  }

  public isHandset$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.Tablet])
    .pipe(map((result: BreakpointState) => result.matches));
  public isHandset: boolean;

  banner: Banner;

  brand: Brand;
  @Input('banner') set _banner(banner: Banner) {
    if (banner) {
      this.banner = banner;
      this.getBrand();
    }
  }
  constructor(
    private localeService: LocaleService,
    private logger: LoggerService,
    private breakpointObserver: BreakpointObserver,
    private brandService: BrandService,
    private bannerService: BannerService
  ) { }

  getBrand() {
    if (this.brandSub) {
      this.brandSub.unsubscribe()
    }
    if (this.banner.brand) {
      this.subs.push(
        this.brandSub = this.brandService.get(this.banner.brand.sys.id).subscribe(brand => {
          if (brand) {
            this.brand = brand;
          }
        })
      );
    }
  }

  trackedClick(banner: Banner) {
    //this.gtm.trackEvent("banner-clicked");
    window.open(banner.destinationUrl, "blank");
  }

  refresh(iteration?: number) {
    if (this.bannerSub) {
      this.bannerSub.unsubscribe();
    }
    if (this.banner) {
      this.bannerService.openBanners[this.banner.id] = false;
    }
    this.subs.push(
      this.bannerSub = this.bannerService.selectRandom(this.isHandset ? 'mobile' : 'desktop', this.layouts, this.brands).subscribe(banners => {
        if (banners && banners.length) {
          this.banner = banners[0];
          this.getBrand();
        }
        else if (iteration < 20) {
          setTimeout(() => {
            this.logger.warn("REITERATE");
            this.refresh(iteration ? ++iteration : 1);
          }, 50);
        }
      })
    );
  }

  ngOnInit() {
    this.subs.push(
      this.localeService.locale$.subscribe((locale: Locale) => {
        if (this.locale !== locale) {
          this.locale = locale;
          this.refresh();
        }
      }),
      this.isHandset$.subscribe(isHandset => {
        if (this.isHandset !== isHandset) {
          this.isHandset = !!isHandset;
          this.refresh();
        }
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
    if (this.banner) {
      this.bannerService.openBanners[this.banner.id] = false;
    }
  }

}
