import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pluralise'
})
export class PluralisePipe implements PipeTransform {

  transform(value: string, count: number = 0, pluralisation: string = "s"): string {
    if (!value) return "";
    if (count === 0 || count > 1) {
      return value + pluralisation;
    }
    return value;
  }
}
