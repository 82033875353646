import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { Link } from 'src/app/models';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html'
})
export class BreadcrumbComponent implements OnInit, OnDestroy {

  private subs: Subscription[] = [];

  title: string;
  @Input('title') set _title(title: string) {
    this.title = title;
  }

  get currentPage(): Link {
    return this.pages[this.pages.length - 1];
  }
  pages: Link[] = [];
  @Input('pages') set _pages(pages: Link[]) {
    this.pages = pages;
    if (!this.title && this.pages.length) {
      this.title = this.currentPage.name;
    }
  }

  constructor(
  ) {}

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
}
