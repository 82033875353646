import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AssetCollection, ContentfulClientApi, createClient, EntryCollection, RichTextDataTarget } from 'contentful';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Asset, ContentfulResponse, ContentQuery, ContentType, DEFAULT_LOCALE, Locale, LOCALES } from 'src/app/models';
import { environment } from 'src/environments/environment';
import { ApiService, CacheService } from '../general';
import { LoggerService } from '../general/logger.service';
import { TransferState } from '@angular/platform-browser';

const API_URL: string = environment.URLS.contentful + '/spaces/' + environment.OPTIONS.contentful.spaceID + '/environments/' + environment.OPTIONS.contentful.env + '/';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {

  private client: ContentfulClientApi;
  private _locale: Locale;
  get locale() {
    if (this._locale) {
      return this._locale;
    }
    if (window && window.localStorage && window.localStorage.getItem("locale")) {
      return this._locale = window.localStorage.getItem("locale") as Locale;
    }
    return DEFAULT_LOCALE;
  }
  set locale(locale: Locale) {
    const l = locale && LOCALES.includes(locale) ? locale : this._locale || DEFAULT_LOCALE;
    if (!this._locale || this._locale !== l) {
      if (window && window.localStorage) {
        window.localStorage.setItem("locale", l);
      }
      this._locale$.next(this._locale = l);
    }
  }
  private _locale$: BehaviorSubject<Locale> = new BehaviorSubject(this.locale);
  get locale$(): Observable<Locale> {
    return this._locale$.asObservable();
  }

  constructor(
    private router: Router,
    private logger: LoggerService,
    private api: ApiService,
    private transferState: TransferState,
    private cache: CacheService,
    @Inject(PLATFORM_ID) private platformId: any,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe((params: Params) => {
      let locale = params.locale as Locale;
      if (!locale) {
        const search = window.location.search;
        const existingURLSearchParams = new URLSearchParams(search);
        locale = existingURLSearchParams.get("locale") as Locale;
      }
      if (locale) {
        // Do nothing, locale parameter is already set
        this.locale = locale;
      }
    });
  }

  addLocaleToQueryParams(locale = this._locale || DEFAULT_LOCALE) {
    this.logger.log("SETTING LOCALE: " + locale);
    this.router.navigate([], {
      queryParams: { locale },
      queryParamsHandling: 'merge',
    });
  }
}
