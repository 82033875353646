import { Component, ContentChild, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Asset } from 'src/app/models';
import { AssetService, LoggerService } from 'src/app/services';

@Component({
  selector: 'asset',
  templateUrl: './asset.component.html'
})
export class AssetComponent implements OnInit, OnDestroy {
  private subs: Subscription[] = [];
  private getAssetSub: Subscription;

  loadType: string = "lazy";
  @Input("eager") set _eager(eager: boolean) {
    if (eager) {
      this.loadType = "eager";
    }
    else {
      this.loadType = "lazy";
    }
  }

  private id: string;
  @Input("id") set _id(id: string) {
    if (this.id !== id) {
      this.id = id;
      this.getAsset();
    }
  }

  @Input("asset") set _asset(asset: Asset) {
    if (asset) {
      this.id = asset.id || asset.sys.id;
      if (asset.file) {
        this.asset = asset;
      }
      else {
        this.getAsset();
      }
    }
  }
  @ContentChild('assetTemplate', { static: true }) assetTemplate: TemplateRef<any>;
  @Input('assetTemplate') set assetTemplateTemplate(ref: TemplateRef<any>) {
    if (ref) {
      this.assetTemplate = ref;
    }
  }
  public asset: Asset;

  constructor(
    private route: ActivatedRoute,
    private assetService: AssetService,
    private logger: LoggerService,
  ) { }

  getAsset() {
    if (this.id) {
      if (this.getAssetSub) {
        this.getAssetSub.unsubscribe()
      }
      this.subs.push(
        this.getAssetSub = this.assetService.get(this.id).subscribe(asset => {
          this.asset = asset;
        })
      );
    }
  }

  ngOnInit() {
    this.subs.push(
      this.route.data.subscribe((data) => {
        if (data["assets"]) {
          this.getAsset();
        }
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

}
