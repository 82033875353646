import { NgModule } from '@angular/core';
import { AutofocusDirective } from './autofocus.directive';
import { BrowserOnlyDirective } from './browser-only.directive';
import { HrefToRouterlinkDirective } from './href-to-routerlink.directive';
import { ServerOnlyDirective } from './server-only.directive';


@NgModule({
  declarations: [
    AutofocusDirective,
    BrowserOnlyDirective,
    ServerOnlyDirective,
    HrefToRouterlinkDirective,
  ],
  exports: [
    AutofocusDirective,
    BrowserOnlyDirective,
    ServerOnlyDirective,
    HrefToRouterlinkDirective,
  ]
})
class DirectivesModule {}

export {
  AutofocusDirective,
  BrowserOnlyDirective,
  ServerOnlyDirective,
  HrefToRouterlinkDirective,

  DirectivesModule
}
