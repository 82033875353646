import { DataObj } from './base.models';

export const skeletonElementDefaults: SkeletonElement = {
  height: 0,
  width: 0,
  widthUnit: "%",
  leftUnit: "%",
  left: 0,
  top: 0,
  radius: 3
}

export type unit = 'px' | '%';

export interface SkeletonElement {
  name?: string;
  height?: number;
  width?: number;
  widthUnit?: unit;
  leftUnit?: unit;
  left?: number;
  right?: number;
  top?: number;
  bottom?: number;
  radius?: number;
}

export class SkeletonColumn extends DataObj implements SkeletonElement {
  name: string;
  height: number;
  width: number;
  widthUnit: unit;
  leftUnit: unit;
  left: number;
  right: number;
  top: number;
  bottom: number;
  radius: number;

  constructor(data?: SkeletonElement, public type = "column") {
    super([
      'name',
      'height',
      'width',
      'widthUnit',
      'leftUnit',
      'left',
      'right',
      'top',
      'radius',
      'bottom',
      'children'
    ], { ...skeletonElementDefaults, ...data });
  }

  amend(updates: SkeletonElement): SkeletonColumn | SkeletonRow {
    return this["__patch__"](updates);
  }

  set(prop: string, value: any): SkeletonColumn {
    if (isDefined(this[prop])) {
      this[prop] = value;
    }
    return this;
  }

}

export interface SkeletonRowBase extends SkeletonElement {
  bottom?: number;
  children?: (SkeletonColumn | SkeletonRow)[];
}

export class SkeletonRow extends SkeletonColumn implements SkeletonRowBase {
  name: string;
  height: number;
  width: number;
  widthUnit: unit;
  leftUnit: unit;
  left: number;
  right: number;
  top: number;
  bottom: number;
  radius: number;
  children: (SkeletonColumn | SkeletonRow)[];

  get asElement(): SkeletonColumn {
    let data: any = this.asDataObj;
    delete data.children;
    delete data.bottom;
    return data;
  }

  constructor(data?: SkeletonRowBase) {
    super({ ...skeletonElementDefaults, ...data });
    this.type = "row";
  }

  prepend(...elements: (SkeletonColumn | SkeletonRow)[]) {
    this.children.unshift(...elements);
    return this;
  }

  append(...elements: (SkeletonColumn | SkeletonRow)[]) {
    this.children.push(...elements);
    return this;
  }

  amend(updates: SkeletonElement, index: number = 0): SkeletonRow {
    if (this.children[index]) {
      this.children[index].amend(updates);
    }
    return this;
  }

  replace(callback: (newItem: SkeletonColumn | SkeletonRow, group: SkeletonRow) => SkeletonColumn | SkeletonRow, index: number = 0, context: any = this) {
    if (this.children[index]) {
      this.children[index] = callback.call(context, this.children[index], this);
    }
    return this;
  }

  amendAll(updates: SkeletonElement): SkeletonRow {
    if (this.children.length) {
      this.children.forEach((element, i) => {
        element.amend(updates);
      });
    }
    return this;
  }

  set(prop: string, value: any): SkeletonRow {
    this[prop] = value;
    return this;
  }
}

function isDefined(item: any) {
  return typeof item !== 'undefined';
}
