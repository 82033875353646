<skeleton-loader [loading]="!article" [layout]="skeletonLayout"></skeleton-loader>
<ng-container *ngIf="article">
  <a class="article-link" [routerLink]="['/' + baseUrl, article.slug]">
    <span class="img" [style.backgroundImage]="'url(' + article.coverImage?.fields?.file?.url + ')'"></span>
    <p>
      <span class="title">{{article.shortTitle || article.title}}</span>
      <span class="preview" [innerHTML]="article.bodyPreview|html"></span>
      <span class="link">Read more <mat-icon>chevron_right</mat-icon></span>
    </p>
  </a>
</ng-container>
