import { ContentfulConfiguration } from './models'

const spaceID = "91v7lnnll1oe";

export const contentfulDevConfig: ContentfulConfiguration = {
  spaceID,
  env: "dev",
  homePageId: "7a7IqNm97K4z0vtHK3RLN1",
  contactPageId: "3Cy7M50FLqyIwRNG2r6Pam",
  privacyPageId: "48ouORzISjR2j4xRnr4mS0",
  apiKey: "5mHfiNSpfydjmPOvotv9F7bN-lL7VGDsJUtbOuGCbFk",
  url: "https://cdn.contentful.com"
}

export const contentfulStagingConfig: ContentfulConfiguration = {
  spaceID,
  env: "master",
  homePageId: "7a7IqNm97K4z0vtHK3RLN1",
  contactPageId: "3Cy7M50FLqyIwRNG2r6Pam",
  privacyPageId: "48ouORzISjR2j4xRnr4mS0",
  apiKey: "nmX1rYAnaSAczAR6bJDOxIDgVPNLTuu3e8WLBRDh8WA",
  url: "https://preview.contentful.com"
}

export const contentfulProdConfig: ContentfulConfiguration = {
  spaceID,
  env: "master",
  homePageId: "7a7IqNm97K4z0vtHK3RLN1",
  contactPageId: "3Cy7M50FLqyIwRNG2r6Pam",
  privacyPageId: "48ouORzISjR2j4xRnr4mS0",
  apiKey: "5mHfiNSpfydjmPOvotv9F7bN-lL7VGDsJUtbOuGCbFk",
  url: "https://cdn.contentful.com"
}
