<ul class="pillar-pages-list">
  <li *ngFor="let page of pillarPages; index as i"
      [class.purple]="page?.color?.toUpperCase() === '#4E1AC8'"
      [class.green]="page?.color?.toUpperCase() === '#7DC827'"
      [class.pink]="page?.color?.toUpperCase() === '#AE00E2'"
      [class.red]="page?.color?.toUpperCase() === '#F17458'"
      [class.active]="isCurrentPage(page)">
    <ng-container *ngIf="template; else noTemplate">
      <ng-template [ngTemplateOutlet]="template"
                   [ngTemplateOutletContext]="{ $implicit: page, index: i, count: pillarPages.length }"></ng-template>
    </ng-container>
    <ng-template #noTemplate>
      <a [routerLink]="page.slug" class="text-link">{{page.title}}</a>
    </ng-template>
  </li>
</ul>
