import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TransferState } from '@angular/platform-browser';
import { ReplaySubject } from 'rxjs';
import { ContentType, PillarPage, Article, ContentfulEntry } from 'src/app/models';
import { CacheService } from '../general';
import { LoggerService } from '../general/logger.service';
import { ContentfulService } from './contentful.service';
import { EntryService } from './entry.service';
import { LocaleService } from './locale.service';

@Injectable({
  providedIn: 'root'
})
export class PillarService extends EntryService<PillarPage> {

  listBy = "slug";
  order = "order";
  private currentPillar$: ReplaySubject<PillarPage> = new ReplaySubject(1);
  get currentPillar() {
    return this.currentPillar$.asObservable();
  }

  constructor(
    client: ContentfulService,
    localeService: LocaleService,
    cache: CacheService,
    logger: LoggerService,
    transferState: TransferState,
    @Inject(PLATFORM_ID) public platformId: any,
  ) {
    super(ContentType.PILLAR_PAGE, client, localeService, logger, cache, platformId, transferState);
  }

  setCurrentPillar(pillarPage: PillarPage) {
    this.currentPillar$.next(pillarPage);
  }

  map(entries: PillarPage[]) {
    return entries.map(entry => {
      entry.featuredArticleIds = entry.featuredArticles.map(article => article.sys.id);
      return entry;
    });
  }

  private init() {
    this.getAll().subscribe();
  }

}
