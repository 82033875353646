<app-breadcrumb [pages]="breadcrumbTrail"></app-breadcrumb>

<div class="contact-us">
  <app-sidebar>
    <section class="section image-right-40">
      <div class="text">
        <h3>{{content?.heading}}</h3>
        <div [innerHTML]="content?.body|html"></div>
      </div>
      <div class="image">
        <img src="/assets/img/contact/contact-image1.png" loading="lazy" />
      </div>
    </section>
    <div class="rounded-box-offlight mt-2 mb-2">
      <b>{{'Email'|contentful|async}}:</b> <a class="text-link underlined text-primary ml-1" href="mailto:{{'support@datarich.io'|contentful|async}}">{{'support@datarich.io'|contentful|async}}</a>
    </div>

  </app-sidebar>
</div>
