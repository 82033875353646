import { User as FirebaseUser } from 'firebase/auth';
import { initializeApp } from "firebase/app";
import { ReplaySubject } from 'rxjs';
export { AuthCredential, EmailAuthProvider, updatePassword, updateEmail, reauthenticateWithCredential, UserCredential, createUserWithEmailAndPassword, getAuth, Auth, signInWithEmailAndPassword, sendPasswordResetEmail, confirmPasswordReset, verifyPasswordResetCode, signOut } from 'firebase/auth';
export { Functions, getFunctions, HttpsCallableResult, httpsCallable } from 'firebase/functions';

import { environment } from 'src/environments/environment';
import { Unsubscribe } from 'firebase/firestore';
export const firebaseConfig = {
  apiKey: environment.KEYS.firebase,
  ...environment.OPTIONS.firebase
};

export interface FirestoreSnapshotResponse<T = any> {
  value: T;
  unsubscribe: Unsubscribe;
  subject?: ReplaySubject<FirestoreSnapshotResponse<T>>;
}

const initializeFirebase = () => initializeApp(firebaseConfig);

export {
  FirebaseUser,
  initializeFirebase
}
