import { collection, CollectionReference, deleteDoc, doc, DocumentReference, DocumentSnapshot, getDoc, getFirestore, onSnapshot, query, setDoc } from 'firebase/firestore';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { FirestoreObjectBase, FirestoreObject } from 'src/app/models';
import { LoggerService } from '../general';
import { FirestoreSnapshotResponse } from './firebase.service';

export abstract class iFirestoreService<T, TBase> {
  collection?: CollectionReference<T>;
  create?: (item: T) => Promise<T>;
  update?: (id: string, item: TBase) => Promise<T>;
  delete?: (item: T) => Promise<boolean>;
  getAll?: () => Observable<FirestoreSnapshotResponse<T[]>>;
  getByProject?: (projectID: string) => Observable<T[]>;
  getByID?: (id: string) => Observable<FirestoreSnapshotResponse<T>>;
  map?: (data: any) => T;
}

export abstract class FirestoreService<T = FirestoreObject, TBase = FirestoreObjectBase> implements iFirestoreService<T, TBase> {

  private beforeCreate: ((data: T) => T)[] = [];
  private beforeUpdate: ((id: string, data: TBase) => TBase)[] = [];
  private beforeDelete: ((id: string, data: any) => DocumentReference)[] = [];
  public collection?: CollectionReference<T>;
  get subs(): FirestoreSnapshotResponse[] {
    return Object.values(this.subsMap);
  }
  subsMap: { [id: string]: FirestoreSnapshotResponse } = {};
  logger: LoggerService

  constructor(
    private collectionName: string = ""
  ) {
    this.collection = collection(getFirestore(), this.collectionName) as CollectionReference<T>;
  }

  onBeforeCreate(fn: (data: T) => any, ...args: any[]) {
    this.beforeCreate.push(fn.bind(this));
  }

  onBeforeUpdate(fn: (id: string, data: TBase) => any, ...args: any[]) {
    this.beforeUpdate.push(fn.bind(this));
  }

  onBeforeDelete(fn: (id: string, data: TBase) => any, ...args: any[]) {
    this.beforeDelete.push(fn.bind(this));
  }

  map(data: any): T {
    return data;
  }

  /**
   * @method FirestoreService.create
   * @description Create the entry for a given Item in firestore
   */
  public async create(data: T): Promise<T> {
    let d = (<any>data) as FirestoreObject;
    let ref: DocumentReference;
    if (!d.id) {
      ref = await doc(this.collection);
      d.id = ref.id;
    }
    else {
      ref = await doc(this.collection, d.id);
    }
    d.updated_at = d.created_at = new Date().getTime();
    if (this.beforeCreate.length) {
      this.beforeCreate.forEach(fn => fn(d as any))
      d = (<any>this.beforeCreate.reduce((prev: T, fn) => fn(prev), (<any>d) as T)) as FirestoreObject;
    }
    return setDoc(ref, d.asDataObj as any)
      .then(() => (<any>d) as T)
      .catch(error => {
        if (this.logger) {
          this.logger.warn("FirestoreService.create: Error creating " + this.collectionName + ": " + error.message);
        }
        return null as T;
      });
  }

  /**
   * @method FirestoreService.update
   * @description Update the entry for a given Item in firestore
   */
  public async update(id: string, data: TBase): Promise<T> {
    if (!id) {
      return Promise.reject(new Error("FirestoreService.update for " + this.collectionName + ": No ID provided."));
    }
    const ref = doc(this.collection, id);
    let d = (<any>data) as FirestoreObjectBase;
    d.updated_at = new Date().getTime();
    if (this.beforeUpdate.length) {
      d = this.beforeUpdate.reduce((prev: TBase, fn) => fn(id, prev), d as TBase)
    }
    return setDoc(ref, d as any, { merge: true })
      .then(() => {
        this.logger.log("FirestoreService.update for " + this.collectionName + ": Successfully updated!");
        return getDoc(ref);
      })
      .catch(error => {
        if (this.logger) {
          this.logger.warn("FirestoreService.update: Error writing " + this.collectionName + ": " + error.message);
        }
        return null;
      });
  }

  /**
   * @method FirestoreService.getByID
   * @description Retrieve the entry for a given Item in firestore
   * @returns Observable<T>
   */
  public getByID(id: string, ...args: any[]): Observable<FirestoreSnapshotResponse<T>> {
    if (!id) {
      return throwError(new Error("FirestoreService.getByID: Supplied parameter 'id' is invalid. Expected T.id of type string, instead received '" + id + "' of type '" + typeof id + "'"));
    }

    if (this.subs[this.collectionName + "/" + id]) {
      return this.subs[this.collectionName + "/" + id].subject.asObservable();
    }

    const ref = doc(this.collection, id);
    const subject = new ReplaySubject<FirestoreSnapshotResponse<T>>(1);
    const unsubscribe = onSnapshot(ref, (doc: DocumentSnapshot) => {
      if (!doc.exists) {
        subject.error(new Error("Document with ID " + id + " not found"));
      }
      else {
        const data = this.map(doc.data());
        const output: FirestoreSnapshotResponse<T> = {
          value: data,
          unsubscribe,
          subject,
        };
        subject.next(output);
      }
    }, error => {
      return subject.error(new Error("FirestoreService.getAll: Error getting " + this.collectionName + ": " + error.message));
    });
    this.subs[this.collectionName + "/" + id] = {
      value: null,
      unsubscribe,
      subject
    };
    return subject.asObservable();
  }

  /**
   * @method FirestoreService.getAll
   * @description Get all the T available in firestore
   * @returns Observable<T[]>
   */
  public getAll(...args: any[]): Observable<FirestoreSnapshotResponse<T[]>> {

    if (this.subs[this.collectionName]) {
      return this.subs[this.collectionName].subject.asObservable();
    }

    const subject = new ReplaySubject<FirestoreSnapshotResponse<T[]>>(1);
    const q = query(this.collection);
    const unsubscribe = onSnapshot(q,
      querySnapshot => {
        if (!querySnapshot && !querySnapshot.size) {
          return subject.error(new Error("FirestoreService.getAll: Error getting " + this.collectionName));
        }
        subject.next({
          value: querySnapshot.docs.map(doc => this.map(doc.data())),
          unsubscribe,
          subject,
        });
      },
      error => {
        return subject.error(new Error("FirestoreService.getAll: Error getting " + this.collectionName + ": " + error.message));
      }
    );
    this.subs[this.collectionName] = {
      value: null,
      unsubscribe,
      subject,
    };
    return subject.asObservable();
  }

  /**
   * @method FirestoreService.delete
   * @description Delete a T record from Firestore
   */
  public delete(data: T, ...args: any[]): Promise<boolean> {
    let d: FirestoreObject = <any>data;
    if (!d || !d.id) {
      return Promise.reject(new Error("FirestoreService.delete for " + this.collectionName + ": data has no ID."));
    }
    const ref = doc(this.collection, d.id);
    return deleteDoc(ref)
      .then(() => true)
      .catch(() => false);
  }

  destroy() {
    this.subs.forEach((s) => s.unsubscribe());
  }
}
