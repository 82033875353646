import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { first, switchMap, map } from 'rxjs/operators';
import { Article } from 'src/app/models';
import { ArticleService, PillarService } from 'src/app/services';

@Injectable({
  providedIn: 'root'
})
export class ArticlesResolver implements Resolve<Article[]> {

  constructor(
    private articleService: ArticleService,
    private pillarService: PillarService,
    @Inject(PLATFORM_ID) public platformId: any,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Article[]> {
    //return this.articleService.getAll(true).pipe(first());
    //console.log("ArticlesResolver");

    // This method of getting articles for each pillar is not working - when the local db is empty, only the request for the first pillar is called and all the rest get cancelled. Not sure why. Until the db is full, it does not work, even though it is requesting fresh data from the server
    //return this.pillarService.getAll().pipe(
    //  first(),
    //  switchMap(pillars => {
    //    if (pillars && pillars.length) {
    //      return forkJoin(pillars.map(p => this.articleService.getByPillar(p.id, 0, true))).pipe(
    //        map((results) => {
    //          const articles = [].concat(...results);
    //          return articles;
    //        }),
    //        first()
    //      );
    //    }
    return this.articleService.getAll().pipe(first());
    //  })
    //);
    //return of(null);

    // Below fires within 2s, and loads all content. Best solution yet, but it circumvents the cache
    //const key = makeStateKey<Article[]>(ContentType.ARTICLE);
    //const transferState = this.transferState.get(key, null);
    //if (transferState) {
    //  this.transferState.remove(key);
    //  return of(transferState);
    //}
    //return this.contentful.getEntriesByType<Article>(ContentType.ARTICLE, { limit: 3, skip: 0, order: "fields.date" }).pipe(tap(entries => {
    //  if (isPlatformServer(this.platformId)) {
    //    this.transferState.set(key, entries);
    //  }
    //}))
  }
}
