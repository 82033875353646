import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { PillarPage } from 'src/app/models';
import { PillarService } from 'src/app/services';

@Injectable({
  providedIn: 'root'
})
export class PillarPagesResolver implements Resolve<PillarPage[]> {

  constructor(
    private pillarService: PillarService,
  ) {}

  resolve(): Observable<PillarPage[]> {
    return this.pillarService.getAll().pipe(first());
  }
}
