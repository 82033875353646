import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { LoggerService } from '../services';


@Directive({
  selector: '[hrefToRouterlink]',
  exportAs: 'hrefToRouterlink'
})
export class HrefToRouterlinkDirective implements OnInit, OnDestroy {

  constructor(
    public el: ElementRef<HTMLInputElement>,
    private router: Router,
    private logger: LoggerService
  ) { }

  onClick = ((event: Event) => {
    try {
      const a: HTMLAnchorElement = (<Element>event.target).closest("a");
      const url = new URL(a.href);
      if (a.href && (url.origin === window.location.origin || url.origin === environment.URLS.domain)) {
        if (event) {
          event.preventDefault();
          event.stopPropagation();
        }
        this.router.navigateByUrl(url.pathname);
      }
    } catch (e) {

    }
  }).bind(this);

  ngOnInit() {
    this.el.nativeElement.addEventListener("click", this.onClick)
  }

  ngOnDestroy() {
    this.el.nativeElement.removeEventListener("click", this.onClick)
  }
}
