import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { LayoutMainComponent } from './layout-main/layout-main.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { LocaleSelectorComponent } from './locale-selector/locale-selector.component';
import { MegaMenuComponent } from './mega-menu/mega-menu.component';
import { BackToTopButtonComponent } from './back-to-top-button/back-to-top-button.component';


@NgModule({
  imports: [
    SharedModule,
  ],
  exports: [
    LayoutMainComponent,
    SidebarComponent,
    BreadcrumbComponent,
  ],
  declarations: [
    LayoutMainComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    BreadcrumbComponent,
    LocaleSelectorComponent,
    MegaMenuComponent,
    BackToTopButtonComponent,
  ]
})
export class LayoutModule { }
