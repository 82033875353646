<div class="sidebar-layout container">
  <div class="content">
    <ng-content></ng-content>
  </div>
  <div class="sidebar">
    <sticky-container stickTo="bottom" [offset]="120">
      <div class="padding-container">
        <nav class="text-small">
          <div class="article-lists" *ngIf="currentPillar">
            <div class="featured-articles" *ngIf="currentPillar?.featuredArticleIds">
              <h4>Featured articles from <a [routerLink]="['/', currentPillar?.slug]" [style.color]="currentPillar?.color">{{currentPillar?.shortTitle || currentPillar?.title}}</a></h4>
              <ul class="articles-container">
                <ng-container *ngFor="let id of currentPillar?.featuredArticleIds">
                  <li *ngIf="!currentArticleId || id !== currentArticleId">
                    <app-featured-article [id]="id" [baseUrl]="currentPillar?.slug"></app-featured-article>
                  </li>
                </ng-container>
              </ul>
            </div>
            <div class="more-articles">
              <h4 class="mt-0 pt-0 mb-1">More articles from <a [routerLink]="['/', currentPillar?.slug]" [style.color]="currentPillar?.color">{{currentPillar?.shortTitle}}</a></h4>
              <app-articles-list [parentPage]="currentPillar"
                                 [hiddenArticles]="hiddenArticleIds"
                                 [hideMoreButton]="true"
                                 [limit]="20">
                <ng-template #template let-article>
                  <a class="article-link" [routerLink]="['/' + currentPillar?.slug, article?.slug]">
                    {{article?.shortTitle || article?.title}}
                  </a>
                </ng-template>
              </app-articles-list>
            </div>
          </div>

          <div class="pillar-list">
            <h4 class="mt-0 pt-0 mb-1">Other sections to explore</h4>
            <app-pillar-pages-list>
              <ng-template #template let-page>
                <a class="inner-template"
                   [routerLink]="['/', page.slug]">
                  <asset class="icon" [id]="page.iconInColor?.id || page.iconInColor?.sys?.id"></asset>
                  <p>{{page.shortTitle || page.title}}</p>
                </a>
              </ng-template>
            </app-pillar-pages-list>
          </div>
        </nav>
        <ng-container *ngIf="showBanner && bannerPlacement">
          <app-banner [count]="1" [layouts]="bannerPlacement?.layouts" [brands]="bannerPlacement?.brands"></app-banner>
        </ng-container>
      </div>
    </sticky-container>
  </div>
</div>
