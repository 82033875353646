import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { forkJoin, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ContentType, ContentTypes, isDefined, Locale } from 'src/app/models';
import { LoggerService } from './logger.service';
import { UtilService } from './util.service';

export const API_SERVICE_CACHE_DATABASE = "api_service_cache_database";
export const API_SERVICE_CACHE_VERSION = 25;
export const GENERAL_STORE_NAME = "APP";

export interface CacheItem<T = any> {
  id: string;
  createdAt: number;
  value: T;
}

declare const global: any;

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  private dbTimers: { [name: string]: any } = {};

  // indexedDB Structure:
  // {
  //   [method: string]: {
  //     [urlWithParams: string]: CacheItem;
  //   },
  //   [contentTypeId: string]: {
  //     [id: string]: CacheItem;
  //   }
  // }
  private get serverCache(): { [type: string]: { [key: string]: CacheItem } } {
    return ((window || global) as any).localCache = ((window || global) as any).localCache || {};
  }

  private options = {
    duration: {
      mins: 30,
      hours: 0
    }
  };
  private db: IDBDatabase;
  private verbose: boolean = false;
  locale: Locale;
  storeNames = [...ContentTypes, "GET", GENERAL_STORE_NAME];

  constructor(
    private util: UtilService,
    private logger: LoggerService,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    if (isPlatformBrowser(this.platformId)) {
      const request = window.indexedDB.open(API_SERVICE_CACHE_DATABASE, API_SERVICE_CACHE_VERSION);
      request.onsuccess = () => {
        this.db = request.result;
      };
      request.onerror = () => {
        this.logger.warn("indexedDB failed to initiate");
      };
      request.onupgradeneeded = (event: any) => {
        this.logger.groupCollapsed("CacheService: UPGRADE");
        this.logger.log("indexedDB has changed. Flushing and recreating all ObjectStores...");
        const db: IDBDatabase = event.target.result;

        // Create an objectStore for this database
        this.storeNames.forEach((type) => {
          try {
            db.deleteObjectStore(type);
          } catch (e) { }
          try {
            db.createObjectStore(type, { keyPath: "id" });
          } catch (e) { }
        });

        this.logger.groupEnd();
      };
    }
  }

  localeCheck(locale: Locale): Observable<{ id: string; value: Locale }> {
    return this.getItem("locale", GENERAL_STORE_NAME).pipe(
      catchError(() => of(null)),
      switchMap(resp => {
        if (!resp || resp.value !== locale) {
          this.storeNames.forEach((type) => {
            try {
              this.db.transaction(type, "readwrite")
                .objectStore(type)
                .clear();
            } catch (e) { }
          });
          return this.setItem("locale", {
            id: "locale",
            value: locale
          }, GENERAL_STORE_NAME);
        }
        return of(resp);
      })
    )
  }

  private getValue<T>(item: CacheItem<T>) {
    if (isDefined(item && item.value) && !this.util.isExpired(item.createdAt, this.options.duration.mins, this.options.duration.hours)) {
      if (this.verbose) {
        this.logger.groupCollapsed("CacheService.getValue");
        this.logger.log("item.value: ", item.value);
        this.logger.groupEnd();
      }
      return item.value;
    }
    return null;
  }

  private getValues<T>(items: CacheItem<T>[]) {
    return items.map(item => this.getValue(item)).filter(item => !!item);
  }

  /**
   * @method CacheService.getItem
   * @description Gets an item from the cache
   */
  getItem<T = any>(key: string, type: string = "GET"): Observable<T> {
    const sub = new Subject<T>();
    if (isPlatformBrowser(this.platformId)) {
      const timerName = type + "_GET_All";
      if (this.dbTimers[timerName]) {
        clearTimeout(this.dbTimers[timerName]);
        delete this.dbTimers[timerName]
      }
      this.util.wait(() => !!this.db && this.db.objectStoreNames.contains(type), 50, 100, this).subscribe(
        () => {
          const request = this.db.transaction(type, "readonly")
            .objectStore(type)[key ? 'get' : 'getAll'](key);

          request.onsuccess = (event) => {
            if (this.dbTimers[timerName]) {
              clearTimeout(this.dbTimers[timerName]);
              delete this.dbTimers[timerName]
            }
            const result: CacheItem<T> = (<any>event.target).result;
            const value = this.getValue(result);
            if (this.verbose) {
              this.logger.groupCollapsed("CacheService.getItem");
              this.logger.log("KEY: " + key);
              this.logger.log("RESULT: ", result);
              this.logger.log("VALUE: ", value);
              this.logger.groupEnd();
            }
            if (value) {
              sub.next(value);
              sub.complete();
            }
            else {
              this.dbTimers[timerName] = setTimeout(() => {
                this.logger.warn(type + " failed to load from indexedDb")
                sub.error(event);
                sub.complete();
              }, 300);
            }
          };

          request.onerror = event => {
            this.logger.groupCollapsed("CacheService.getItem.onerror");
            this.logger.log("KEY: " + key);
            this.logger.warn("EVENT: " + event)
            sub.error(event);
            sub.complete();
            this.logger.groupEnd();
          };
        },
        () => {
          sub.error(new Error("CacheService.getItem: indexedDB did not load in time"));
        }
      );
    }
    else if (this.serverCache[type] && this.serverCache[type][key]) {
      const value = this.getValue(this.serverCache[type][key]);
      if (value) {
        sub.next(value);
        sub.complete();
      }
      else {
        delete this.serverCache[type][key];
        sub.error(new Error("CacheService.getItem: No value in serverCache"));
        sub.complete();
      }
    }
    else {
      sub.error(new Error("CacheService.getItem: No indexDb in SSR mode and serverCache for '" + type + "' is empty"));
    }
    return sub.asObservable();
  }



  /**
   * @method CacheService.getItems
   * @description Gets all items from the cache
   */
  getItems<T = any>(type: string): Observable<T[]> {
    this.logger.log("CacheService.getItems: " + type);

    const sub = new Subject<T[]>();
    if (isPlatformBrowser(this.platformId)) {
      const timerName = type + "_GET_All";
      if (this.dbTimers[timerName]) {
        clearTimeout(this.dbTimers[timerName]);
        delete this.dbTimers[timerName]
      }
      this.util.wait(() => !!this.db, 50, 100, this).subscribe(
        () => {
          const request = this.db.transaction(type, "readonly")
            .objectStore(type)
            .getAll();

          request.onsuccess = (event) => {
            if (this.dbTimers[timerName]) {
              clearTimeout(this.dbTimers[timerName]);
              delete this.dbTimers[timerName]
            }
            const results: CacheItem<T>[] = (<any>event.target).result;
            const values = this.getValues(results);
            if (this.verbose) {
              this.logger.groupCollapsed("CacheService.getItems");
              this.logger.log("RESULT: ", results);
              this.logger.log("VALUE: ", values);
              this.logger.groupEnd();
            }
            if (values && values.length && values.length === results.length) {
              sub.next(values);
              sub.complete();
            }
            else {
              this.dbTimers[timerName] = setTimeout(() => {
                sub.error(event);
                sub.complete();
              }, 300);
            }
          };

          request.onerror = event => {
            this.logger.groupCollapsed("CacheService.getItems.onerror");
            this.logger.warn("EVENT: " + event)
            sub.error(event);
            sub.complete();
            this.logger.groupEnd();
          };
        },
        () => {
          sub.error(new Error("CacheService.getItems: indexedDB did not load in time"));
        }
      );
    }
    else if (this.serverCache[type]) {
      const cacheItems = Object.values(this.serverCache[type]);
      const values = this.getValues(cacheItems);
      if (values && values.length && values.length === cacheItems.length) {
        sub.next(values);
        sub.complete();
      }
      else {
        delete this.serverCache[type];
        sub.error(new Error("CacheService.getItems: No value in serverCache"));
        sub.complete();
      }
    }
    else {
      sub.error(new Error("CacheService.getItems: No indexDb in SSR mode and serverCache for '" + type + "' is empty"));
    }
    return sub.asObservable();
  }

  /**
   * @method CacheService.setItem
   * @description Stores an item in the cache
   */
  private setItem<T = any>(key: string, value: T, type: string = "GET"): Observable<T> {
    const sub = new Subject<T>();
    const cacheItem: CacheItem = {
      value,
      id: key,
      createdAt: new Date().getTime()
    };
    if (isPlatformBrowser(this.platformId)) {
      this.util.wait(() => !!this.db, 50, 50, this).subscribe(
        () => {

          const request = this.db.transaction(type, "readwrite")
            .objectStore(type)
            .put(cacheItem);

          request.onsuccess = (event: Event) => {
            if (this.verbose) {
              this.logger.groupCollapsed("CacheService.setItem.onsuccess");
              this.logger.log("KEY: " + key);
              this.logger.log(event);
              this.logger.groupEnd();
            }
            sub.next(value);
            sub.complete();
          };

          request.onerror = event => {
            this.logger.groupCollapsed("CacheService.setItem.onerror");
            this.logger.log("URL: " + key);
            this.logger.log(event);
            sub.error(event);
            sub.complete();
            this.logger.groupEnd();
          };
        },
        () => {
          sub.error(new Error("CacheService.setItem: indexedDB did not load in time"));
        }
      );
    }
    else {
      this.serverCache[type] = this.serverCache[type] || {};
      this.serverCache[type][key] = cacheItem;
      sub.next(value);
    }
    return sub.asObservable();
  }

  /**
   * @method CacheService.processUrlRequest
   * @description ...
   * @param req The request to be processed
   * @param T The type of object to be returned
   */
  public processUrlRequest<T>(url: string): Observable<T> {

    const cacheObs = this.getItem<T>(url);

    return cacheObs.pipe(
      map((value) => {
        if (value) {
          return value;
        }
        throw new Error("Nothing in the cache for " + url);
      })
    );
  }

  /**
   * @method CacheService.processUrlResponse
   * @description ...
   * @param url The URL to be processed
   * @param value The value to be assigned to the URL
   * @param T The type of object to be returned
   */
  public processUrlResponse<T>(url: string, value: T): Observable<T> {
    return this.setItem(url, value);
  }

  /**
   * @method CacheService.processContentRequest
   * @description ...
   * @param type The type of content to be processed
   * @param id The id of a specific item to lookup
   * @param T The type of object to be returned
   */
  public processContentRequest<T>(type: ContentType, id?: string): Observable<T> {

    const cacheObs = this.getItem<T>(id, type);

    return cacheObs.pipe(
      map((value) => {
        if (value) {
          return value;
        }
        throw new Error("Nothing in the cache for " + id);
      })
    );
  }

  processContentResponseSubs: Subscription[] = [];

  /**
   * @method CacheService.processContentResponse
   * @description ...
   * @param T The type of object to be returned
   * @param type {ContentType} The type of content to be processed
   * @param value {T[]} The value to be assigned to the content type
   * @param identifier {string} The property from T to use as an ID in the database
   */
  public processContentResponse<T>(type: ContentType, value: T[], identifier: string = "id"): void {
    const obs = forkJoin([
      ...value.map((item) => this.setItem(item[identifier] || new Date().toISOString(), item, type))
      //...(identifier !== "id" ? value.map((item) => this.setItem((<any>item).id || new Date().toISOString(), item, type)) : [])
    ]);
    const idx = this.processContentResponseSubs.length;
    this.processContentResponseSubs.push(
      obs.pipe(
        catchError((err: Error) => {
          this.logger.log("processContentResponse() error: ", err.message);
          return of(value);
        }),
      ).subscribe(() => {
        if (this.processContentResponseSubs[idx]) {
          this.processContentResponseSubs[idx].unsubscribe();
        }
      })
    );
  }
}
