import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { Subscription } from 'rxjs';
import { Page } from 'src/app/models';
import { LoggerService } from 'src/app/services';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit, OnDestroy {
  private subs: Subscription[] = [];
  content: Page;

  constructor(
    private route: ActivatedRoute,
    private logger: LoggerService,
  ) { }

  getFirstPage(route: ActivatedRouteSnapshot = this.route.snapshot) {
    this.content = route.data['homePage'];
  }

  ngOnInit() {
    this.subs.push(
      this.route.data.subscribe((data) => {
        if (data["homePage"]) {
          this.getFirstPage();
        }
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

}
