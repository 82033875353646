import { DataObj, DataSpec } from './base.models';
import { isDefined } from './data.models';

export interface FirestoreObjectBase {
  id?: string;
  archived?: boolean;
  created_at?: number;
  updated_at?: number;
}

export class FirestoreObject<T = FirestoreObjectBase> extends DataObj<T> implements FirestoreObjectBase {
  id: string;
  archived: boolean;
  created_at: number;
  updated_at: number;

  constructor(specs?: (DataSpec | string)[], data?: T) {
    if (specs) {
      specs.unshift(
        'id',
        'archived',
        { name: 'created_at', defaultValue: new Date().getTime() },
        { name: 'updated_at', defaultValue: new Date().getTime() },
      );
    }
    super(specs, data);
  }

  patch(data) {
    this["__patch__"](data);
    return this;
  }
}


export enum UserRole {
  SuperAdmin = 0,
  Admin = 1,
  User = 2,
  All = 3 //This should always be the last entry; new roles replace this entry and move this one to the end of the enum
}

export interface UserBase extends FirestoreObjectBase {
  email?: string;
  first_name?: string;
  last_name?: string;
  inactive?: boolean;
  role?: UserRole;
}

export class User extends FirestoreObject<UserBase> implements UserBase {
  role: UserRole;
  email: string;
  first_name: string;
  last_name: string;
  inactive: boolean;

  get getRole() {
    return isDefined(this.role) ? this.role : UserRole.User;
  }

  constructor(data?: UserBase) {
    super([
      { name: 'role', defaultValue: UserRole.User },
      'email',
      'first_name',
      'last_name',
      'inactive',
    ], data);
  }
}

export interface EmailBase extends FirestoreObjectBase {
  email_address?: string;
  signup_path?: string;
  subscribed?: boolean;
  validated?: boolean;
}

export class Email extends FirestoreObject<EmailBase> implements EmailBase {
  email_address: string;
  signup_path: string;
  subscribed?: boolean;
  validated: boolean;

  constructor(data?: UserBase) {
    super([
      'email_address',
      'sign_up_path',
      'subscribed',
      'validated',
    ], data);
  }

}
