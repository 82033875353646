import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tel'
})
export class TelephonePipe implements PipeTransform {

  transform(value: string | number): string {
    const num = "" + value;
    if (num.length === 10) {
      return `(${num.substr(0, 3)}) ${num.substr(3, 3)}-${num.substr(6)}`
    }
    return num;
  }
}
