<div class="pillar-page article-page">

  <app-breadcrumb [pages]="breadcrumbTrail"></app-breadcrumb>

  <app-sidebar [bannerPlacement]="content?.sidebarBannerPlacement">
    <div class="content-body" *ngIf="content" hrefToRouterlink>
      <div class="content-placeholder" [innerHTML]="content?.body|html:isHandset"></div>
    </div>
    <not-found-component class="content-body" *ngIf="!loading && !content"></not-found-component>
    <skeleton-loader class="content-body" [layout]="skeletonLoader" *ngIf="loading && !content"></skeleton-loader>

  </app-sidebar>
</div>
