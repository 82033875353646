import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { initializeFirebase } from './app/services';

initializeFirebase();

if (environment.production || environment.staging) {
  enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.error(err));

if (document.readyState === 'complete') {
  console.log("document.readyState === 'complete'");
  bootstrap();
} else {
  console.log("DOMContentLoaded");
  document.addEventListener('DOMContentLoaded', bootstrap);
}

