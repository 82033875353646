<skeleton-loader [loading]="loading" [layout]="skeletonLayout"></skeleton-loader>
<ng-container *ngIf="articles?.length">
  <ng-content></ng-content>
  <ul class="articles-container">
    <li *ngFor="let article of articles">
      <ng-container *ngIf="template; else noTemplate">
        <ng-template [ngTemplateOutlet]="template" [ngTemplateOutletContext]="{ $implicit: article, parent: parentPage || allParentPages[article.pillar?.id || article.pillar?.sys?.id] }"></ng-template>
      </ng-container>
      <ng-template #noTemplate>
        <a [routerLink]="['/' + (parentPage?.slug || allParentPages[article.pillar?.id || article.pillar?.sys?.id]?.slug || ''), article.slug]" class="text-link">{{article.title}}</a>
        <div class="preview-text" [innerHTML]="article.bodyPreview|html"></div>
      </ng-template>
    </li>
  </ul>
  <p *ngIf="!articles?.length && !hideEmptyMessage"><span class="unselectable">{{'No articles available'|contentful|async}}</span></p>
  <div class="d-flex justify-content-center" *ngIf="!hideMoreButton && (articles?.length < total)">
    <button (click)="loadMore($event)" class="btn more-btn">
      <i *ngIf="loadingMore" class="fa-solid fa-circle-notch fa-spin"></i>
      <span *ngIf="!loadingMore">{{moreButtonLabel || ('More Posts'|contentful|async)}} <!--({{articles?.length}} of {{total}})--></span>
    </button>
  </div>
</ng-container>
