import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { UtilService, FirestoreEmailsService } from '../../../services';
import { Email, EmailBase } from '../../../models';
import { emailStrictValidator } from '../../../validators';

@Component({
  selector: 'dr-email-capture-form',
  templateUrl: './email-capture-form.component.html'
})
export class EmailCaptureFormComponent implements OnInit, OnDestroy {

  private subs: Subscription[] = [];
  year: number = new Date().getFullYear();

  form: FormGroup = this.fb.group({
    email_address: ['', emailStrictValidator()],
  });
    errorTimer: any;
    success: boolean;
    successTimer: any;
    loading: boolean;

  get email_address() {
    return this.form.get('email_address');
  }
  errorMsg: string;
  waitTime: number = 10000;

  constructor(
    private fb: FormBuilder,
    private util: UtilService,
    private emailsService: FirestoreEmailsService
  ) { }

  save(event?: Event) {
    if (event) {
      event.preventDefault();
    }
    if (!this.loading) {
      if (this.successTimer) {
        clearTimeout(this.successTimer);
      }
      if (this.errorTimer) {
        clearTimeout(this.errorTimer);
      }
      this.success = false;
      this.errorMsg = "";
      if (this.form.valid) {
        const email = new Email({
          ...this.form.value,
          id: this.email_address.value,
          subscribed: true,
          validated: false,
          signup_path: window ? window.location.pathname : 'unknown'
        } as EmailBase);
        this.loading = true;
        this.emailsService.create(email)
          .then(() => {
            this.success = true;
            this.loading = false;
            this.util.markFormGroupUntouched(this.form);
            this.successTimer = setTimeout(() => {
              this.success = false;
            }, this.waitTime);
          })
          .catch((err) => {
            this.errorMsg = err && err.message || "An error occurred";
            this.loading = false;
            this.errorTimer = setTimeout(() => {
              this.errorMsg = "";
            }, this.waitTime);
          });
      }
      else {
        this.util.markFormGroupTouched(this.form);
      }
    }
  }

  ngOnInit() {
    this.subs.push(
      this.email_address.valueChanges.subscribe((value: string) => {
        if (value && value.includes(" ")) {
          this.email_address.setValue(value.replace(/\s/gi, ""));
        }
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
    if (this.successTimer) {
      clearTimeout(this.successTimer);
    }
    if (this.errorTimer) {
      clearTimeout(this.errorTimer);
    }
  }
}
