import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { ContentLoaderModule } from '@ngneat/content-loader';
import { DirectivesModule } from 'src/app/directives';
import { PipesModule } from '../../pipes';
import { ArticlesListComponent } from './articles-list/articles-list.component';
import { AssetComponent } from './asset/asset.component';
import { BannerComponent } from './banner/banner.component';
import { EmailCaptureFormComponent } from './email-capture-form/email-capture-form.component';
import { FeaturedArticleComponent } from './featured-article/featured-article.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PillarPagesListComponent } from './pillar-pages-list/pillar-pages-list.component';
import { RibbonComponent } from './ribbon/ribbon.component';
import { SkeletonLoaderComponent } from './skeleton-loader/skeleton-loader.component';
import { StickyContainerComponent } from './sticky-container/sticky-container.component';

@NgModule({
  imports: [
    //Angular Modules
    CommonModule, RouterModule,
    FormsModule, ReactiveFormsModule,

    //Material Modules
    MatIconModule,
    MatMenuModule,

    //Skeleton Content Loader
    ContentLoaderModule,

    //App Modules
    PipesModule,
    DirectivesModule,
  ],
  exports: [
    //Angular Modules
    CommonModule, RouterModule,
    FormsModule, ReactiveFormsModule,

    //Material Modules
    MatIconModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatChipsModule,
    MatMenuModule,

    //Skeleton Content Loader
    ContentLoaderModule,
    SkeletonLoaderComponent,

    //Shared Modules
    PipesModule,
    DirectivesModule,

    //Shared Components
    ArticlesListComponent,
    AssetComponent,
    BannerComponent,
    NotFoundComponent,
    PillarPagesListComponent,
    RibbonComponent,
    StickyContainerComponent,
    EmailCaptureFormComponent,
    FeaturedArticleComponent,
  ],
  declarations: [
    SkeletonLoaderComponent,

    ArticlesListComponent,
    AssetComponent,
    BannerComponent,
    NotFoundComponent,
    PillarPagesListComponent,
    RibbonComponent,
    StickyContainerComponent,
    EmailCaptureFormComponent,
    FeaturedArticleComponent,
  ]
})
export class SharedModule { }
