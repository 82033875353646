import { Injectable } from '@angular/core';
import { RichTextDataTarget } from 'contentful';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Asset, ContentfulResponse, ContentQuery, ContentType } from 'src/app/models';
import { environment } from 'src/environments/environment';
import { ApiService } from '../general';
import { LoggerService } from '../general/logger.service';
import { LocaleService } from './locale.service';

const API_URL: string = environment.URLS.contentful + '/spaces/' + environment.OPTIONS.contentful.spaceID + '/environments/' + environment.OPTIONS.contentful.env + '/';

@Injectable({
  providedIn: 'root'
})
export class ContentfulApiService {

  constructor(
    private logger: LoggerService,
    private api: ApiService,
    private localeService: LocaleService,
  ) {}

  queryLog: { [content_type: string]: ContentfulResponse } = {};

  private getEntries<T = any>(query?: ContentQuery): Observable<T[]> {
    const url = API_URL + 'entries';

    return this.localeService.locale$.pipe(switchMap(() =>
      this.api.get(url, {
        ...(query || {}),
        locale: this.localeService.locale
      }, null, null, true, true).pipe(
        map((resp: ContentfulResponse) => {
          if (query && query.content_type) {
            this.queryLog[query.content_type] = resp;
          }
          if (resp && resp.items) {
            const base: any = {};
            if (resp.includes) {
              base.includes = resp.includes;
            }
            return resp.items.map(entry => ({
              ...base,
              ...entry.sys,
              ...entry.fields,
            })) as T[];
          }
          return [] as T[];
        }),
        catchError((err) => {
          this.logger.warn("An error occurred: " + err && err.message);
          return of([] as T[]);
        })
      )
    )) as Observable<T[]>;
  }

  getEntriesByType<T = any>(type: ContentType, orderBy?: string): Observable<T[]>;
  getEntriesByType<T = any>(type: ContentType, query?: ContentQuery): Observable<T[]>;
  getEntriesByType<T = any>(...args: any[]): Observable<T[]> {
    const type: ContentType = args[0];
    const query = args[1];
    if (query && typeof query === "string") {
      return this.getEntries<T>({ 'content_type': type, order: 'fields.' + query });
    }
    return this.getEntries<T>({ 'content_type': type, order: 'sys.updatedAt', ...(query || {}) });
  }

  getEntryByField<T = any>(fieldName: string, fieldValue: string, type: ContentType) {
    return this.getEntries<T>({
      ['fields.' + fieldName]: fieldValue,
      'content_type': type,
      limit: 1
    }).pipe(map(arr => arr && arr[0] as T));
  }

  getEntriesByField<T = any>(fieldName: string, fieldValue: string, type: ContentType) {
    return this.getEntries<T>({
      ['fields.' + fieldName]: fieldValue,
      'content_type': type
    })
  }

  getEntry<T = any>(id: string): Observable<T> {
    const url = API_URL + 'entries/' + id;

    return this.api.get(url).pipe(
      map((resp: ContentfulResponse) => {
        return resp ? {
          ...resp.sys,
          ...resp.fields
        } : null;
      }),
      catchError((err) => {
        this.logger.warn("An error occurred: " + err && err.message);
        return of(null);
      })
    ) as Observable<T>;
  }

  getAsset(id: string): Observable<Asset> {
    const url = API_URL + 'assets/' + id;

    return this.api.get(url).pipe(
      map((resp: ContentfulResponse) => {
        return resp ? {
          ...resp.fields,
          metadata: resp.metadata,
          id: resp.sys.id
        } as Asset : null;
      }),
      catchError((err) => {
        this.logger.warn("An error occurred: " + err && err.message);
        return of(null);
      })
    );
  }

  getAssets(query?: ContentQuery): Observable<Asset[]> {
    const url = API_URL + 'assets';

    return this.api.get(url, {
      ...(query || {}),
      locale: this.localeService.locale
    }).pipe(
      map((resp: ContentfulResponse) => {
        return resp ? {
          ...resp.fields,
          metadata: resp.metadata,
          id: resp.sys.id
        } as Asset : null;
      }),
      catchError((err) => {
        this.logger.warn("An error occurred: " + err && err.message);
        return of(null);
      })
    );
  }

  retrieveLink(link: RichTextDataTarget) {
    if (link.sys.linkType === "Asset") {
      return this.getAsset(link.sys.id);
    }
    return this.getEntry(link.sys.id);
  }

}
