import { isPlatformBrowser } from '@angular/common';
import { Component, OnDestroy, OnInit, Input, PLATFORM_ID, Inject, HostListener, NgZone } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { Link } from 'src/app/models';

@Component({
  selector: 'back-to-top-button',
  templateUrl: './back-to-top-button.component.html'
})
export class BackToTopButtonComponent implements OnInit, OnDestroy {

  private subs: Subscription[] = [];
  private isPlatformBrowser = isPlatformBrowser(this.platformId);
  private scrollTimer: any;
  private scrollCounter: number = 0;
  private maxScrollCounter: number = 50;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private zone: NgZone
  ) { }

  showButton = false;

  onWindowScroll() {
    if (this.isPlatformBrowser) {
      if (this.scrollTimer) {
        clearTimeout(this.scrollTimer);
      }
      if (this.scrollCounter > this.maxScrollCounter) {
        this.scrollCounter = 0;
        this.checkScroll();
      }
      else {
        ++this.scrollCounter;
        this.scrollTimer = setTimeout(() => {
          this.checkScroll();
        }, 150);
      }
    }
  }

  checkScroll() {
    this.zone.run(() => {
      this.showButton = window.scrollY > 300; // Adjust the threshold as needed
    });
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  ngOnInit() {
    this.subs.push(
      fromEvent(window, "scroll").subscribe(() => this.onWindowScroll())
    );
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
}
