import { FirebaseConfiguration } from './models'

export const firebaseDevConfig: FirebaseConfiguration = {
  authDomain: "data-rich-dev.firebaseapp.com",
  projectId: "data-rich-dev",
  storageBucket: "data-rich-dev.appspot.com",
  messagingSenderId: "784375471624",
  appId: "1:784375471624:web:cd3c46d37d57ed0abafbbd",
  measurementId: "G-9ZYZ70W5ZH",
  apiKey: "AIzaSyDoSKqc5Uoq4jWWvCDjMfEBjHk1wv2Af6Q",
  url: ""
}

export const firebaseStagingConfig: FirebaseConfiguration = {
  authDomain: "data-rich-staging.firebaseapp.com",
  projectId: "data-rich-staging",
  storageBucket: "data-rich-staging.appspot.com",
  messagingSenderId: "108388315897",
  appId: "1:108388315897:web:1870bb36f6a3a50631e828",
  measurementId: "G-9ZYZ70W5ZH",
  apiKey: "AIzaSyC-4x1rYAaY6q2UewJl6-gLiMYwT09R2Pg",
  url: ""
}

export const firebaseProdConfig: FirebaseConfiguration = {
  authDomain: "data-rich-bd970.firebaseapp.com",
  projectId: "data-rich-bd970",
  storageBucket: "data-rich-bd970.appspot.com",
  messagingSenderId: "812102971576",
  appId: "1:812102971576:web:32d9cd1f4b90d63dbdc44d",
  measurementId: "G-9ZYZ70W5ZH",
  apiKey: "AIzaSyCmeIN6IfjSQmbwGm1oeHlMsAVe_6hRP90",
  url: ""
}
