import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Pipe, PipeTransform, PLATFORM_ID, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { Block, BLOCKS, Document, INLINES } from '@contentful/rich-text-types';
import { Asset, Entry } from 'contentful';
import { BannerPlacement, Brand, ContentType } from 'src/app/models';
import { BannerService, LoggerService } from 'src/app/services';

@Pipe({
  name: 'html',
  pure: true
})
export class HTMLPipe implements PipeTransform {

  bannerIds: string[] = [];
  parser: DOMParser;

  renderOptions(isHandset: boolean = true) {
    return {
      renderNode: {
        [INLINES.EMBEDDED_ENTRY]: (node) => {
          // target the contentType of the EMBEDDED_ENTRY to display as you need
          try {
            if (node.data.target.sys.contentType.sys.id === ContentType.BANNER_PLACEMENT) {

              const bannerPlacement: BannerPlacement = node.data.target.fields;
              if (bannerPlacement) {
                const type: 'mobile' | 'desktop' = isHandset ? 'mobile' : 'desktop';
                const banner = this.bannerService.selectRandomFromCache(type, bannerPlacement.layouts, bannerPlacement.brands)[0];
                if (banner) {
                  this.bannerIds.push(banner.id);
                  return `<div class="banner-wrapper">
                          <div class="d-flex flex-column align-items-center"">
                            <a class="banner-link cursor-pointer"
                               target="_blank"
                               href="${banner.destinationUrl}"
                               data-entity="banner"
                               data-banner-id="${banner.id}"
                               data-banner-name="${banner.name}"
                               data-banner-brand-name="${(<Entry<Brand>>banner.brand).fields.name}">
                              <img src="https://${(<Asset>banner.image).fields.file.url}"
                                   alt="${(<Asset>banner.image).fields.description}"
                                   loading="lazy" />
                            </a>
                          </div>
                        </div>`;
                }
                this.logger.log('[No matching banner]')
                return '';
              }
              this.logger.log('[No matching bannerPlacement (id: ' + node.data.target.sys.id + ')]')
              return '';
            }
            this.logger.log('[No matching entry type (type: ' + node.data.target.sys.contentType.sys.id + ')]')
            return '';
          }
          catch (e) {
            this.logger.warn('[An error occurred: ' + e.message + ']')
            return '';
          }
        },

        [BLOCKS.EMBEDDED_ASSET]: (node) => {
          try {
            return `<img src="https://${node.data.target.fields.file.url}"
                         alt="${node.data.target.fields.description}"
                         loading="lazy" />`;
          }
          catch (e) {
            this.logger.warn('[An error occurred: ' + e.message + ']')
            return '';
          }
        },

        [BLOCKS.EMBEDDED_ENTRY]: (node) => {
          try {
            return `<img src="https://${node.data.target.fields.file.url}"
                         alt="${node.data.target.fields.description}"
                         loading="lazy" />`;
          }
          catch (e) {
            this.logger.warn('[An error occurred: ' + e.message + ']')
            return '';
          }

        },
      },
    };
  }

  dataAttributes: Object[];

  constructor(
    private logger: LoggerService,
    private sanitizer: DomSanitizer,
    @Inject(PLATFORM_ID) private platformId: any,
    private bannerService: BannerService
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.parser = new DOMParser();
    }
  }

  stripDataAttributes(rawHtml: string) {
    this.dataAttributes = [];

    let htmlDoc = this.parser.parseFromString(rawHtml, 'text/html');
    htmlDoc.querySelectorAll("[data-entity='banner']").forEach((el) => {
      const attrs = el.attributes;
      const elDataAttributes: any = {};
      this.logger.log(el.attributes);
      for (var i = 0; i < attrs.length; i++) {
        const item = attrs.item(i);
        if (item.nodeName.indexOf('data-') === 0) {
          elDataAttributes[item.nodeName] = item.nodeValue;
        }
      }
      this.dataAttributes.push(elDataAttributes);
      el.classList.add("data-attributes-removed");
    });

    return htmlDoc.body.innerHTML;
  }

  restoreDataAttributes(sanitizedHtml: string) {
    if (this.dataAttributes && this.dataAttributes.length) {
      let htmlDoc = this.parser.parseFromString(sanitizedHtml, 'text/html');
      let index = 0;
      htmlDoc.querySelectorAll(".data-attributes-removed").forEach((el) => {
        const elDataAttributes = this.dataAttributes[index];
        if (elDataAttributes) {
          Object.keys(elDataAttributes).forEach(nodeName => {
            const nodeValue = elDataAttributes[nodeName];
            el.setAttribute(nodeName, nodeValue)
          });
          el.classList.remove("data-attributes-removed");
        }
        ++index;
      });
      return htmlDoc.body.innerHTML;
    }
    return sanitizedHtml;
  }

  transform(value: Block, isHandset?: boolean): SafeHtml {
    if (this.bannerIds.length) {
      this.bannerIds.forEach(id => this.bannerService.openBanners[id] = false);
      this.bannerIds = [];
    }
    if (isPlatformServer(this.platformId)) {
      let html = documentToHtmlString(value as Document, this.renderOptions(isHandset));
      return this.sanitizer.sanitize(SecurityContext.HTML, html);
    }
    if (value) {
      let html = this.stripDataAttributes(documentToHtmlString(value as Document, this.renderOptions(isHandset)));
      const sanitizedHtml = this.sanitizer.sanitize(SecurityContext.HTML, html);
      return this.sanitizer.bypassSecurityTrustHtml(this.restoreDataAttributes(sanitizedHtml));
    }
    return '';
  }
}
