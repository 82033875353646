import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { random } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Banner, Brand, ContentfulEntry, ContentType } from 'src/app/models';
import { LoggerService } from '../general/logger.service';
import { ContentfulService } from './contentful.service';
import { EntryService } from './entry.service';
import { CacheService } from '../general';
import { TransferState } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { LocaleService } from './locale.service';

@Injectable({
  providedIn: 'root'
})
export class BannerService extends EntryService<Banner> {

  private mobileBannerOptions: Banner[] = [];
  private desktopBannerOptions: Banner[] = [];

  openBanners: { [id: string]: boolean } = {};

  constructor(
    contentful: ContentfulService,
    localeService: LocaleService,
    cache: CacheService,
    logger: LoggerService,
    public transferState: TransferState,
    @Inject(PLATFORM_ID) public platformId: any,
  ) {
    super(ContentType.BANNER, contentful, localeService, logger, cache, platformId, transferState);
    this.init();
  }

  private init() {
    this.getAll().subscribe(banners => {
      this.mobileBannerOptions = banners.filter(b => b.mobile);
      this.desktopBannerOptions = banners.filter(b => b.desktop);
    });
  }

  selectRandomFromCache(type: 'mobile' | 'desktop', layouts?: string[], brands?: ContentfulEntry<Brand>[], count: number = 1, allowDuplicates?: boolean): Banner[] {
    let options: Banner[] = [...(type === 'mobile' ? this.mobileBannerOptions : this.desktopBannerOptions)];
    if (layouts) {
      options = options.filter(b => {
        return layouts.findIndex((l) => b.layout && b.layout.includes(l)) > -1;
      })
    }
    if (brands) {
      options = options.filter(banner => {
        const bannerId = banner.brand.sys.id;
        return brands.findIndex((b) => {
          const brandId = b.sys.id;
          return bannerId === brandId;
        }) > -1;
      })
    }
    let banners: Banner[] = [];
    if (options.length) {
      // We only have enough options to fulfill the request, don't bother about filtering for duplicates
      if (options.length <= count && allowDuplicates) {
        return options;
      }

      // We have more options than required, go ahead with filtering for duplicates
      const unusedOptions: Banner[] = [];
      const usedOptions: Banner[] = [];
      options.forEach(banner => {
        if (this.openBanners[banner.id]) {
          usedOptions.push(banner);
        }
        else {
          unusedOptions.push(banner);
        }
      });

      // We have exactly enough unusedOptions to fulfill the request
      if (unusedOptions.length === count) {
        banners = unusedOptions;
      }
      else {
        // Select the correct number of items to return (to a maximum of options.length)
        // if we have an unused banners we will select that first
        banners = this.selectRandomFromList(unusedOptions, count, banners);
        if (count > banners.length && allowDuplicates) {
          // if we still don't have enough to fulfil, we will include duplicate options if allowDuplicates is true
          banners = this.selectRandomFromList(usedOptions, count, banners);
        }
      }
    }
    if (banners.length) {
      banners.forEach(banner => this.openBanners[banner.id] = true)
    }
    return banners;
  }

  selectRandom(type: 'mobile' | 'desktop', layouts?: string[], brands?: ContentfulEntry<Brand>[], count: number = 1, allowDuplicates?: boolean): Observable<Banner[]> {
    return this.entries.pipe(map(() => this.selectRandomFromCache(type, layouts, brands, count, allowDuplicates)));
  }

  private selectRandomFromList(options: Banner[], count: number = 1, banners: Banner[] = []) {
    if (options.length) {
      let lastIndex = options.length - 1;
      while (count > 0 && lastIndex > -1) {
        const rnd = random(0, lastIndex);
        if (options[rnd]) {
          const banner = options.splice(rnd, 1)[0];
          banners.push(banner);
          this.openBanners[banner.id] = true;
          lastIndex--;
          count--;
        }
      }
    }
    return banners;
  }

}
