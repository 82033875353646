import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Link, PillarPage, SkeletonRow, ContentType } from 'src/app/models';
import { ContentfulService, LoggerService, PillarService, SeoService, SkeletonService } from 'src/app/services';

@Component({
  selector: 'app-pillar-page',
  templateUrl: './pillar-page.component.html'
})
export class PillarPageComponent implements OnInit, OnDestroy {

  private subs: Subscription[] = [];
  private slug: string;
  content: PillarPage;
  redirectTimer: any;
  loading: boolean;

  skeletonLoader: SkeletonRow[] = [
    this.skeletonService.getTitle({ width: 350 }),
    this.skeletonService.getSubtitle(),
    this.skeletonService.getTextBlock(5),
    this.skeletonService.getRectangles(1, 80, 300, { top: 10, bottom: 15, left: 10 }),
    this.skeletonService.getTextBlock(3, { top: 0 }),
    this.skeletonService.getButtons(2, { top: 30, left: 24.5 })
  ];

  breadcrumbTrail: Link[] = [
    { name: 'Home', link: '/' },
  ];

  public isHandset$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.Tablet])
    .pipe(map((result: BreakpointState) => result.matches));
  public isHandset: boolean;

  constructor(
    private logger: LoggerService,
    private router: Router,
    private route: ActivatedRoute,
    private seoService: SeoService,
    private breakpointObserver: BreakpointObserver,
    private contentful: ContentfulService,
    private skeletonService: SkeletonService,
    private pillarService: PillarService,
  ) { }

  getPage() {
    if (this.slug) {
      this.subs.push(
        this.contentful.getEntryByField('slug', this.slug, ContentType.PILLAR_PAGE).subscribe(pillarPage => {
          if (pillarPage) {
            this.setContent(pillarPage);
          }
          else {
            this.redirect();
          }
        })
      );
    }
  }

  getPageFromRoute() {
    const pillarPage: PillarPage = this.route.snapshot.data['pillarPage'];
    if (pillarPage && pillarPage.body) {
      this.setContent(pillarPage);
    }
    else {
      this.getPage();
    }
  }

  setContent(pillarPage: PillarPage) {
    if (this.redirectTimer) {
      clearTimeout(this.redirectTimer);
    }
    this.content = pillarPage;
    this.pillarService.setCurrentPillar(pillarPage);
    this.logger.log("Pillar: ", this.content);
    const name = this.content.shortTitle || this.content.title;
    if (this.breadcrumbTrail.length > 1) {
      this.breadcrumbTrail = [this.breadcrumbTrail[0]];
    }
    this.breadcrumbTrail.push({
      link: '/' + this.content.slug,
      name
    });
    this.seoService.setTitle(this.content.metaTitle || (name + " | DataRich"));
    if (this.content.metaDescription) {
      this.seoService.setMetaTag("description", this.content.metaDescription);
    }
    this.loading = false;
  }

  redirect() {
    this.logger.error("Content not found");
    if (this.redirectTimer) {
      clearTimeout(this.redirectTimer);
    }
    this.redirectTimer = setTimeout(() => {
      this.router.navigate(['/'], {
        queryParamsHandling: 'preserve'
      });
    }, 500);
  }

  ngOnInit() {
    this.loading = true;
    this.subs.push(
      // Subscribe to route.params so we get an update everytime the route changes,
      // but the data we actually want is in route.data
      this.route.params.pipe(switchMap((params) => {
        this.slug = params["pillarPage"];
        return this.route.data;
      })).subscribe((data) => {
        if (data['pillarPage']) {
          this.getPageFromRoute();
        }
        else {
          this.getPage();
        }
      }),
      this.isHandset$.subscribe(isHandset => {
        if (this.isHandset !== isHandset) {
          this.isHandset = !!isHandset;
        }
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

}
