import { NgModule } from '@angular/core';
import { LayoutModule } from '../layout/layout.module';
import { SharedModule } from '../shared/shared.module';

import { ArticleComponent } from './article/article.component';
import { PillarPageComponent } from './pillar-page/pillar-page.component';
import { HomeComponent } from './home/home.component';
import { ContactComponent } from './contact/contact.component';
import { PrivacyComponent } from './privacy/privacy.component';

@NgModule({
  imports: [
    SharedModule,
    LayoutModule
  ],
  declarations: [
    PillarPageComponent, ArticleComponent,
    HomeComponent, PrivacyComponent, ContactComponent
  ],
  exports: [
  ],
})
class ContentModule { }

export {
  ContentModule,
  PillarPageComponent, ArticleComponent,
  HomeComponent, PrivacyComponent, ContactComponent
}
