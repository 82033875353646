import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'asArray'
})
export class AsArrayPipe implements PipeTransform {

  transform(obj: Object): any {
    return Object.values(obj);
  }
}
