import { Pipe, PipeTransform } from '@angular/core';

import { LoggerService } from '../services';

@Pipe({
  name: 'log'
})
export class LogPipe implements PipeTransform {

  constructor(private logger: LoggerService) {}

  transform(value: any): string {
    this.logger.log(value);
    return "";
  }
}
