import { Document } from '@contentful/rich-text-types';
import { Asset as cAsset, Metadata } from 'contentful';


interface ContentfulAsset extends cAsset {
  id?: string;
}


export enum Locale {
  EN_US = "en-US",
  EN_GB = "en-GB",
  ES_US = "es-US"
}

export const LOCALES = ["en-US", "en-GB", "es-US"];

export const DEFAULT_LOCALE: Locale = Locale.EN_US;

export interface Content {
  id?: string;
  type?: string;
  title?: string;
  shortTitle?: string;
  metaTitle?: string;
  metaDescription?: string;
  metaKeywords?: string;
  updatedAt?: string;
  createdAt?: string;
  locale?: Locale;
  includes?: ContentfulIncludes;
  sys?: {
    id: string;
  };
}

export interface ContentLink {
  sys: {
    id: string;
    linkType?: string;
    type: string;
  };
}

export interface ContentQuery {
  locale?: Locale;
  content_type?: ContentType;
  skip?: number;
  limit?: number;
  order?: string;
  [fieldName: string]: string | any; //['fields.myFieldName']: "fieldValue",
}

export interface ContentfulPagedResponse<T = any> extends ContentQuery {
  id?: string;
  items?: T[];
  resp_log?: ContentfulResponse<T>;
}

export interface ContentfulIncludes<T = any> {
  Asset: ContentfulEntry<T>[];
  Entry: ContentfulEntry<T>[];
}

export interface ContentfulEntry<T = any> {
  id?: string;
  sys?: {
    id?: string;
    type?: "Array" | "Object" | "Link";
  };
  fields?: T;
  metadata?: any;
}

export interface ContentfulResponse<T = any> extends ContentfulEntry {
  total?: number;
  skip?: number;
  limit?: number;
  items?: ContentfulEntry<T>[];
  includes?: ContentfulIncludes<T>;
}

export enum ContentType {
  SERVICE = "service",
  CUSTOMER_SOURCE = "customerSource",
  DICTIONARY_LOOKUP = "dictionaryLookup",
  ARTICLE = "article",
  PILLAR_PAGE = "pillarPage",
  BANNER = "banner",
  BANNER_PLACEMENT = "bannerPlacement",
  ASSET = "Asset",
  PERSON = "person",
  BRAND = "brand",
  COLOR = "color",
  PAGE = "page"
}

export const ContentTypes = [
  ContentType.SERVICE,
  ContentType.CUSTOMER_SOURCE,
  ContentType.DICTIONARY_LOOKUP,
  ContentType.ARTICLE,
  ContentType.PILLAR_PAGE,
  ContentType.BANNER,
  ContentType.BANNER_PLACEMENT,
  ContentType.ASSET,
  ContentType.PERSON,
  ContentType.BRAND,
  ContentType.COLOR,
  ContentType.PAGE
];

export interface DictionaryLookup extends Content {
  value: string;
  property: string;
}

export interface Color extends Content {
  value: string;
  name: string;
}

export interface Article extends Content {
  author?: ContentfulEntry<Author>;
  body?: Document;
  bodyPreview?: any;
  coverImage?: ContentfulAsset;
  sidebarBannerPlacement?: ContentfulEntry<BannerPlacement>;
  pillar?: ContentfulEntry<PillarPage>;
  date?: string;
  slug?: string;
  tags?: string[];
  title?: string;
  shortTitle?: string;
}

export interface SimplePage extends Content {
  heading?: string;
  body?: Document;
  images?: ContentfulAsset[];
}

export interface Page extends Content {
  heroPreHeading?: string;
  heroHeading?: string;
  heroSubHeading?: string;
  heroImage?: ContentfulAsset;
  heroText?: Document;

  section1PreHeading?: string;
  section1Heading?: string;
  section1SubHeading?: string;
  section1Image?: ContentfulAsset;
  section1Text?: Document;
  section1Callout?: Document;

  section2PreHeading?: string;
  section2Heading?: string;
  section2SubHeading?: string;
  section2Image?: ContentfulAsset;
  section2Text?: Document;
  section2Callout?: Document;
}

export interface PillarPage extends Content {
  author?: ContentfulEntry<Author>;
  body?: Document;
  color?: string;
  heroImage?: ContentfulAsset;
  iconInColor?: ContentfulAsset;
  iconInPurple?: ContentfulAsset;
  order?: number;
  featuredArticles?: Article[];
  featuredArticleIds: string[];
  sidebarBannerPlacement?: ContentfulEntry<BannerPlacement>;
  slug?: string;
  summary?: Document;
  tags?: string[];
}

export interface Author extends Content {
  company: string;
  email: string;
  facebook: string;
  github: string;
  image: ContentfulAsset;
  name: string;
  phone: string;
  shortBio: string;
  title: string;
  twitter: string;
}

export interface FileDetails {
  size: number;
  image?: { width: number; height: number; };
}

export interface File {
  contentType: string;
  details: FileDetails;
  fileName: string;
  url: string;
}

export interface Asset extends ContentLink {
  id?: string;
  title?: string;
  description?: string;
  file?: File;
  metadata?: Metadata;
}

export interface Banner extends Content {
  name?: string;
  mobile?: boolean;
  desktop?: boolean;
  vertical?: boolean;
  destinationUrl?: string;
  image?: ContentfulAsset;
  brand?: ContentfulEntry<Brand>;
  layout?: string[];
}

export interface Brand extends Content {
  name?: string;
}

export interface BannerPlacement extends Content {
  name?: string;
  layouts?: string[];
  brands?: ContentfulEntry<Brand>[];
}
