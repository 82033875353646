import { isPlatformBrowser } from '@angular/common';
import { Component, ContentChild, Inject, Input, OnDestroy, OnInit, PLATFORM_ID, TemplateRef } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Subscription } from 'rxjs';
import { PillarPage } from 'src/app/models';
import { LoggerService, PillarService } from 'src/app/services';

@Component({
  selector: 'app-pillar-pages-list',
  templateUrl: './pillar-pages-list.component.html'
})
export class PillarPagesListComponent implements OnInit, OnDestroy {

  private subs: Subscription[] = [];
  pillarPages: PillarPage[];
  loading = true;

  isBrowser: boolean = isPlatformBrowser(this.platformId);

  @ContentChild('template', { static: true }) template: TemplateRef<any>;

  @Input('template') set _template(ref: TemplateRef<any>) {
    this.template = ref;
  }

  selectedPillar: PillarPage;
  @Input('selected') set _selected(selected: PillarPage) {
    this.selectedPillar = selected;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private pillarService: PillarService,
    private logger: LoggerService,
    @Inject(PLATFORM_ID) public platformId: any,
  ) {
  }

  isCurrentPage(pillar: PillarPage) {
    if (this.isBrowser) {
      if (this.selectedPillar) {
        if (this.selectedPillar.id === pillar.id) {
          return true;
        }
        return false;
      }
      const urlTree: UrlTree = this.router.createUrlTree(['/', pillar.slug]);
      return this.router.isActive(urlTree, false);
    }
    return false;
  }

  getPillars() {
    this.subs.push(
      this.pillarService.getAll()
        .subscribe(pillarPages => {
          if (pillarPages) {
            this.pillarPages = pillarPages;
          }
          this.loading = false;
        })
    );
  }


  getFirstPage(route: ActivatedRouteSnapshot = this.route.snapshot) {
    this.pillarPages = route.data['pillarPages'];
  }

  ngOnInit() {
    this.subs.push(
      this.route.data.subscribe((data) => {
        if (data["pillarPages"]) {
          this.getFirstPage();
        }
        else {
          this.getPillars();
        }
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
}
