import { CurrencyPipe, DatePipe, isPlatformBrowser, JsonPipe, LocationStrategy, PercentPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_ID, Inject, NgModule, PLATFORM_ID } from '@angular/core';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './components/app.component';
import { ContentModule } from './components/content/content.module';
import { LayoutModule } from './components/layout/layout.module';
import { SharedModule } from './components/shared/shared.module';
import { httpInterceptorProviders } from './interceptors';
import { ConsoleLoggerService, LoggerService, PathPreserveQueryLocationStrategy } from './services';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserTransferStateModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    LayoutModule,
    ContentModule,
    AppRoutingModule
  ],
  providers: [
    {
      provide: LoggerService,
      useClass: ConsoleLoggerService
    },
    { provide: LocationStrategy, useClass: PathPreserveQueryLocationStrategy },
    httpInterceptorProviders, CurrencyPipe, PercentPipe, DatePipe, JsonPipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  firstScript: HTMLElement;

  constructor(
    @Inject(PLATFORM_ID) platformId: Object,
    @Inject(APP_ID) appId: string
  ) {
    const isBrowser = isPlatformBrowser(platformId);
    if (!environment.production) {
      const platform = isBrowser ? "in the browser" : "on the server";
      console.log(`Running ${platform} with appId=${appId}`);
    }
    if (isBrowser) {
      this.firstScript = document.getElementsByTagName('script')[0];
      this.gtmSetup();
      this.cseSetup();
      this.fontAwesomSetup();
      this.stylesSetup();
    }
  }

  stylesheets = []; // "/utilities.css", 

  stylesSetup() {
    this.stylesheets.forEach(path => {
      const link = document.createElement('link');
      link.type = "text/css";
      link.rel = "stylesheet";
      link.href = path;
      this.firstScript.parentNode.appendChild(link);
    })
  }

  fontAwesomSetup() {
    let script = document.createElement('script');
    script.async = true;
    script.crossOrigin = "anonymous";
    script.src = environment.URLS.fontAwesome;
    this.firstScript.parentNode.insertBefore(script, this.firstScript);
  }

  cseSetup() {
    let script = document.createElement('script');
    script.async = true;
    script.src = environment.URLS.cse;
    this.firstScript.parentNode.insertBefore(script, this.firstScript);
  }

  gtmSetup() {
    let script = document.createElement('script');
    script.async = true;
    script.src = environment.URLS.gtm + '?id=' + environment.OPTIONS.gtm.id;
    if (environment.OPTIONS.gtm.dataLayerName) {
      script.src += environment.OPTIONS.gtm.dataLayerName != 'dataLayer' ? '&l=' + environment.OPTIONS.gtm.dataLayerName : '';
    }
    if (environment.OPTIONS.gtm.auth) {
      script.src += '&gtm_auth=' + environment.OPTIONS.gtm.auth;
    }
    if (environment.OPTIONS.gtm.preview) {
      script.src += '&gtm_preview=' + environment.OPTIONS.gtm.preview;
    }
    if (environment.OPTIONS.gtm.cookies) {
      script.src += '&gtm_cookies_win=' + environment.OPTIONS.gtm.cookies;
    }
    this.firstScript.parentNode.insertBefore(script, this.firstScript);
  }
}
