import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { DictionaryService, LocaleService } from '../services';


@Pipe({
  name: 'contentful',
  //pure: true
})
export class ContentfulPipe implements PipeTransform, OnDestroy {

  private subs: Subscription[] = [];
  props: { [propName: string]: { subject: BehaviorSubject<string>; changeCase?: string; propName: string; } } = {};

  constructor(
    private dictionary: DictionaryService,
    private localeService: LocaleService,
  ) { }

  onLocaleChange(propName: string) {
    this.subs.push(
      this.localeService.locale$.subscribe(
        resp => {
          this.getContent(propName);
        }
      )
    );
  }

  getContent(propName: string) {
    const item = this.props[propName];
    this.subs.push(
      this.dictionary.get(propName)
        .subscribe((content) => {
          if (content && content.value) {
            item.subject.next(item.changeCase ? this.changeCase(content.value, item.changeCase) : content.value);
          }
        })
    );
  }

  changeCase(value: string, changeTo?: string) {
    if (!value) return '';
    if (!changeTo) return value;
    switch (changeTo) {
      case 'lower':
      case 'lowercase':
        return value.toLowerCase();
      case 'upper':
      case 'uppercase':
        return value.toUpperCase();
      case 'sentence':
        let sentences = value.split('.');
        let newVal = "";
        sentences.forEach((s, index) => {
          newVal += s.charAt(0).toUpperCase() + s.substr(1).toLowerCase();
        });
        return newVal;
      case 'capitalise':
      case 'title':
      default:
        return value.replace(/\w\S*/g, function (word) {
          return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
        });
    }
  }

  transform(propName: string = '', changeCase?: string): Observable<string> {
    if (propName) {
      this.props[propName] = this.props[propName] || {
        propName,
        changeCase,
        subject: new BehaviorSubject(propName)
      };
      this.getContent(propName);
      this.onLocaleChange(propName);
      return this.props[propName].subject.asObservable();
    }
    return null;
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
}
