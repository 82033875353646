import { Component, ContentChild, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { BannerPlacement, ContentfulEntry, PillarPage } from 'src/app/models';
import { ArticleService, PillarService } from '../../../services';
import { StickyContainerComponent } from '../../shared/sticky-container/sticky-container.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit, OnDestroy {

  private subs: Subscription[] = [];
  currentPillar: PillarPage;
  currentPillarId: string;
  currentArticleId: string;
  hiddenArticleIds: string[] = [];

  @Input() showBanner: boolean = true;

  @ViewChild(StickyContainerComponent) stickyContainer: StickyContainerComponent;

  bannerPlacement: BannerPlacement;
  @Input("bannerPlacement") set _bannerPlacement(bannerPlacement: ContentfulEntry<BannerPlacement>) {
    if (bannerPlacement) {
      this.bannerPlacement = bannerPlacement.fields;
      if (this.stickyContainer) {
        this.stickyContainer.update();
      }
    }
  }

  constructor(
    private pillarService: PillarService,
    private route: ActivatedRoute,
    private articleService: ArticleService,
  ) { }

  ngOnInit() {
    this.subs.push(
      this.pillarService.currentPillar.subscribe(pillar => {
        this.currentPillar = pillar;
        if (pillar) {
          this.hiddenArticleIds = [...this.currentPillar.featuredArticleIds, ...(this.currentArticleId ? [this.currentArticleId] : [])];
        }
        else {
          this.hiddenArticleIds = [];
        }
        if (this.stickyContainer) {
          this.stickyContainer.update();
        }
      }),
      this.articleService.currentArticle.subscribe((article) => {
        if (article) {
          this.currentArticleId = article.id;
          this.hiddenArticleIds = [...(this.currentPillar && this.currentPillar.featuredArticleIds ? this.currentPillar.featuredArticleIds : []), ...(this.currentArticleId ? [this.currentArticleId] : [])];
        }
        else {
          this.currentArticleId = null;
          this.hiddenArticleIds = [];
        }
        if (this.stickyContainer) {
          this.stickyContainer.update();
        }
      }),
    );
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
}
