<p hrefToRouterlink><span [innerHTML]="('Subscribe to our weekly newsletter'|contentful|async)|marked"></span></p>
<form [formGroup]="form" (ngSubmit)="save($event)">
  <label class="input-text" [class.disabled]="loading">
    <input formControlName="email_address" type="email" [placeholder]="'Your Email'|contentful|async" />
    <button class="btn btn-secondary btn-round" type="submit">
      <i class="fa-solid" [ngClass]="loading ? 'fa-circle-notch fa-spin' : 'fa-arrow-right'"></i>
    </button>
  </label>
  <span class="input-message text-warn" *ngIf="email_address?.touched && email_address?.errors?.email">Invalid email address. Please fix and try again.</span>
  <span class="input-message text-warn" *ngIf="errorMsg">{{errorMsg}}</span>
  <span class="input-message text-success" *ngIf="success">Thank you! You&rsquo;ve been added to our mailing list. If at any time you&rsquo;d like to remove yourself, simply click the unsubscribe link in any email you receive.</span>
</form>
