import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { Observable, of, ReplaySubject, Subscription, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Asset, ContentType, DEFAULT_LOCALE, Locale } from 'src/app/models';
import { CacheService, UtilService } from '../general';
import { LoggerService } from '../general/logger.service';
import { ContentfulService } from './contentful.service';
import { LocaleService } from './locale.service';

@Injectable({
  providedIn: 'root'
})
export class AssetService {
  private localeSub: Subscription;
  private locale: Locale = DEFAULT_LOCALE;
  private getAllInitiated: boolean;
  private isPlatformBrowser = isPlatformBrowser(this.platformId);
  private isPlatformServer = isPlatformServer(this.platformId);

  listBy = "id";
  type = ContentType.ASSET;

  private assets$: ReplaySubject<Asset[]> = new ReplaySubject(1);
  private getById: { [id: string]: ReplaySubject<Asset> } = {};
  get assets(): Observable<Asset[]> {
    return this.assets$.asObservable();
  }

  constructor(
    public contentful: ContentfulService,
    public cache: CacheService,
    public logger: LoggerService,
    public transferState: TransferState,
    private localeService: LocaleService,
    public util: UtilService,
    @Inject(PLATFORM_ID) public platformId: any,
  ) {
    if (this.localeSub) {
      this.localeSub.unsubscribe()
    }
    this.localeSub = this.localeService.locale$
      .pipe(
        switchMap((locale) => {
          return this.cache.localeCheck(locale);
        })
      )
      .subscribe((locale) => {
        if (locale && (!this.locale || this.locale !== locale.value)) {
          this.locale = locale.value;
          this.getAll(true);
        }
      });
  }

  get(id: string, force?: boolean): Observable<Asset> {

    this.getById[id] = this.getById[id] || new ReplaySubject(1);

    return this.getAll(force).pipe(
      map(items => items.find(asset => asset.id === id)),
      switchMap(item => item && !force ? of(item) : this.contentful.getAsset(id)),
      tap(item => {
        this.getById[id].next(item);
      })
    );
  }

  getAll(force?: boolean): Observable<Asset[]> {
    if (!this.getAllInitiated || force) {
      this.getAllInitiated = true;
      const currentPage = this.type + "__GET_ALL";
      const key = makeStateKey<Asset[]>(currentPage);
      if (this.isPlatformBrowser) {
        const state = this.transferState.get(key, null);
        if (state) {
          this.assets$.next(state);
          this.transferState.remove(key);
          return this.assets;
        }
      }
      const fresh = () => this.contentful.getAssets().pipe(
        tap(items => {
          if (isPlatformBrowser(this.platformId)) {
            this.cache.processContentResponse(this.type, items, this.listBy);
          }
        })
      );
      const obs = isPlatformServer(this.platformId) ? fresh() : this.cache.getItems<Asset>(this.type);
      return obs.pipe(
        catchError(() => fresh()),
        tap(items => {
          this.assets$.next(items);
          if (this.isPlatformServer) {
            this.transferState.set(key, items);
          }
        })
      );
    }
    return this.assets;
  }

}
