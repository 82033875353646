import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterObj'
})
export class FilterObjPipe implements PipeTransform {

  transform(list: any[] = [], filter?: any): any {
    if (!list) return [];
    if (!filter) return list;
    let ret = list.filter(item => {
      let pass = true;
      for (let prop in filter) {
        if (item[prop] !== filter[prop]) {
          pass = false;
          break;
        }
      }
      return pass;
    });
    return ret;
  }
}
