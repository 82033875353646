import { Injectable, Inject } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { RouteData } from 'src/app/models';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(
    private title: Title,
    private meta: Meta,
    @Inject(DOCUMENT) private document: Document
  ) { }

  private composeTitle(title: string) {
    //const siteRoot = "DataRich";
    //const joiner = " - ";
    const siteRoot = "";
    const joiner = "";
    if (title) {
      return siteRoot + joiner + title;
    }
    return siteRoot;
  }

  /**
   * @name SeoService.setTitle()
   * @description Sets the title of the document
   * @param title This value to apply to the title of the document
   */
  public setTitle(title: string): void {
    this.title.setTitle(this.composeTitle(title));
  }

  /**
   * @name SeoService.setMetaTag()
   * @description Adds or updates a meta tag in the document
   * @param tag The name of the tag to add/update
   * @param value The value to apply to the tag
   */
  public setMetaTag(tag: string, value: string): void {
    if (this.meta.getTag("name='" + tag + "'")) {
      this.meta.updateTag({ name: tag, content: value, });
    }
    else {
      this.meta.addTag({ name: tag, content: value, });
    }
  }

  /**
   * @name SeoService.setMetaTags()
   * @description Adds or updates multiple meta tags in the document
   * @param tags Key/value pairs representing the name/value of meta tags to be added or updated in the document
   */
  public setMetaTags(tags: { [key: string]: string }): void {
    for (let tag in (tags || {})) {
      this.setMetaTag(tag, tags[tag]);
    }
  }

  /**
   * @name SeoService.setCanonicalURL()
   * @description Adds or replaces the cannonical URL <link> element
   * @param url {string} (optional) The URL to be set. If not provided, document.URL will be used as the default.
   */
  public setCanonicalURL(url?: string) {
    const existing: HTMLLinkElement = this.document.head.querySelector("[rel='canonical']");
    const link: HTMLLinkElement = existing || this.document.createElement('link');
    url = url || (environment.URLS.domain + this.document.location.pathname.slice(1));
    link.setAttribute('rel', 'canonical');
    this.document.head.appendChild(link);
    link.setAttribute('href', url);
  }

  /**
   * @name SeoService.setFromRouteData()
   * @description Adds or updates a meta tag in the document by reading the RouteData of a route
   * @param data The route data to be read
   */
  public setFromRouteData(data: RouteData): void {
    data = data || {};
    if (data.title) {
      this.setTitle(data.title);
    }
    if (data.tags) {
      this.setMetaTags(data.tags);
    }
    this.setCanonicalURL(data.url);
  }
}
