<div class="footer-wrapper">
  <footer class="container footer">
    <div class="upper-section">
      <div class="logo">
        <a [routerLink]="['/']">
          <img src="/assets/img/data-rich-logo.svg" loading="lazy" />
          <span class="sr-only">DataRich</span>
        </a>
      </div>
      <nav class="column">
        <h4>{{'Quick Navigation'|contentful|async}}</h4>
        <app-pillar-pages-list>
          <ng-template #template let-page>
            <a [routerLink]="['/', page.slug]" class="nowrap text-link">
              {{page.shortTitle || page.title}}
            </a>
          </ng-template>
        </app-pillar-pages-list>
      </nav>
      <div class="column column-2">
        <h4>{{('Subscribe'|contentful)|async}}</h4>
        <ul>
          <li>
            <dr-email-capture-form></dr-email-capture-form>
          </li>
        </ul>
      </div>
    </div>

    <div class="lower-section">
      <p class="order-2 order-md-1">
        &copy; {{'Copyright'|contentful|async}} {{year}} DataRich | {{'All rights reserved'|contentful|async}}
      </p>
      <ul class="order-1 order-md-2 ml-md-auto">
        <li>
          <a class="text-link underlined" routerLink="/privacy">{{'Privacy Policy'|contentful|async}}</a>
        </li>
        <li>
          <a class="text-link underlined" routerLink="/contact">{{'Contact Us'|contentful|async}}</a>
        </li>
      </ul>
    </div>
  </footer>
</div>

<back-to-top-button></back-to-top-button>
