import { ModuleWithProviders, NgModule } from '@angular/core';
import { AsArrayPipe } from './as-array.pipe';
import { AsFilterPipe } from './as-filter.pipe';
import { ChangeCasePipe } from './change-case.pipe';
import { ContentfulPipe } from './contentful.pipe';
import { FilterListPipe } from './filter-list.pipe';
import { FilterObjPipe } from './filter-obj.pipe';
import { LimitToPipe } from './limit-to.pipe';
import { LogPipe } from './log.pipe';
import { PluralisePipe } from './pluralise.pipe';
import { TelephonePipe } from './telephone.pipe';
import { TypeOfPipe } from './type-of.pipe';
import { MarkedPipe } from './marked.pipe';
import { HTMLPipe } from './html.pipe';


export {
  ChangeCasePipe, ContentfulPipe, TypeOfPipe,
  FilterListPipe, FilterObjPipe,
  AsFilterPipe, LimitToPipe,
  LogPipe,
  PluralisePipe,
  TelephonePipe,
  AsArrayPipe,
  MarkedPipe,
  HTMLPipe
};

@NgModule({
  declarations: [
    ChangeCasePipe,
    ContentfulPipe,
    TypeOfPipe,
    FilterListPipe,
    FilterObjPipe,
    AsFilterPipe,
    LimitToPipe,
    LogPipe,
    PluralisePipe,
    TelephonePipe,
    AsArrayPipe,
    MarkedPipe,
    HTMLPipe
  ],
  exports: [
    ChangeCasePipe,
    ContentfulPipe,
    TypeOfPipe,
    FilterListPipe,
    FilterObjPipe,
    AsFilterPipe,
    LimitToPipe,
    LogPipe,
    PluralisePipe,
    TelephonePipe,
    AsArrayPipe,
    MarkedPipe,
    HTMLPipe
  ]
})
export class PipesModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: PipesModule,
      providers: [
        ChangeCasePipe,
        ContentfulPipe,
        TypeOfPipe,
        FilterListPipe,
        FilterObjPipe,
        AsFilterPipe,
        LimitToPipe,
        LogPipe,
        PluralisePipe,
        TelephonePipe,
        AsArrayPipe,
        MarkedPipe,
        HTMLPipe
      ]
    }
  }
}
