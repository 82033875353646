import { Environment } from './models';
import { firebaseStagingConfig as firebaseConfig } from './config.firebase';
import { contentfulStagingConfig as contentfulConfig } from './config.contentful';

export const environment: Environment = {
  production: false,
  staging: true,
  KEYS: {
    firebase: firebaseConfig.apiKey,
    contentful: contentfulConfig.apiKey,
    indexNow: "78262edf4812443eb7867620bdca2f83"
  },
  OPTIONS: {
    firebase: firebaseConfig,
    contentful: contentfulConfig,
    gtm: {
      id: "GTM-TKBTBPL",
      auth: "5tCsujsRulCuz4CdrQEs3g",
      preview: "env-4",
      cookies: "x"
    }
  },
  URLS: {
    domain: "https://staging.datarich.io/",
    cloudFunctionUrl: firebaseConfig.url,
    contentful: contentfulConfig.url,
    gtm: "https://www.googletagmanager.com/gtm.js",
    cse: "https://cse.google.com/cse.js?cx=112f698b9c9a34273",
    fontAwesome: "https://kit.fontawesome.com/c6a7529505.js"
  }
};
