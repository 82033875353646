import { Pipe, PipeTransform } from '@angular/core';
import { marked } from 'marked';

@Pipe({
  name: 'marked',
  pure: true
})
export class MarkedPipe implements PipeTransform {

  constructor(
  ) { }

  transform(value: string): string {
    if (typeof value === "string") {
      const html = marked.parse(value);
      return html;
    }
    return value;
  }
}
