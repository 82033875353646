import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterList'
})
export class FilterListPipe implements PipeTransform {

  transform(list: any[] = [], filter?: string): any {
    if (!list) return [];
    if (!filter) return list;
    const term = new RegExp(filter.toLowerCase(), 'i');
    return list.filter(p => (
         (typeof p.name === 'string' && (<string>p.name).search(term) > -1)
      || (typeof p.id === 'string' && (<string>p.id).search(term) > -1)
      || (typeof p.file_url === 'string' && (<string>p.file_url).search(term) > -1)
      || (typeof p.customerId === 'string' && (<string>p.customerId).search(term) > -1)
    ));
  }
}
